import { useRef, useState ,useEffect} from 'react';
import axios from 'axios';
import './index.less';
import { Link ,useParams,useNavigate} from 'react-router-dom';


interface User {
    checkin : Date;
    city : string;
    computility : number;
    computilityLimit : number;
    country: string;
    created : Date;
    headimgurl : string;
    id : number;
    name : string;
    openid : string;
    platform : string;
    province : string;
    sex : number;
    silicon : number;
    unionid : string;
}

const eventName = [
    '',
    '多元宇宙事件', //1
    '突发事件', //2
    '世界擂台赛',//3
    '制造',//4
    '初级芯片人礼包',//5
    '直接发起挑战'//6
]


interface AwardItemProps {
    award : {
        hash? : string;
        type : string;
        value : number;
        rate? : string;

    }
}

interface award  {
    type : string;
    value : number;
    hash? : string;
    rate? : string;
};

function AwardItem ( {award} : AwardItemProps  ){
    const [name,setName] = useState<string>()
    const [imageurl,setImageUrl] = useState<string>()
    const [valueString,setValueString] = useState<string>()
    const [tagName,setTagName] = useState<string>()


    useEffect( ()=> {
        if(award.type === "silicon"){
            setImageUrl(`/assets/icons/silicon.svg` )
            setName('硅晶');
            setValueString(award.value.toString())
        }

        if(award.type === "equipment"){

            setValueString(`${( (award.value).toFixed(2)).toString()}`)
            setTagName("装备卡")

            axios.get(`/api/v1/skibidi_toilet_card/equipment?hash=${award.hash}`)
            .then(response => {
                if(response.statusText === "OK"){                    
                    setImageUrl(`https://oss.usecubes.cn/${response.data.equipment.category.image.url}?x-oss-process=image/resize,w_80,limit_0` )
                    setName(response.data.equipment.category.cnName)
                }
            })
        }

        if(award.type === "core"){
            setValueString(`${( (award.value).toFixed(2)).toString()}`)
            setTagName("核心卡")
            axios.get(`/api/v1/skibidi_toilet_card/core?hash=${award.hash}`)
            .then(response => {
                if(response.statusText === "OK"){                    
                    setImageUrl(`https://oss.usecubes.cn/${response.data.core.category.image.url}?x-oss-process=image/resize,w_80,limit_0` )
                    setName(response.data.core.category.cnName)
                }
            })
        }

        if(award.type === "hero"){
            setValueString(`${( (award.value).toFixed(2)).toString()}`)
            setTagName("英雄卡")
            axios.get(`/api/v1/skibidi_toilet_card/herobyhash?hash=${award.hash}`)
            .then(response => {
                if(response.statusText === "OK"){     
                    const hero = response.data.hero;               
                    setImageUrl(  hero.image ? `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_80,limit_0` :  `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_80,limit_0`)
                    setName(hero.category ? hero.category.cnName : '')
                }
            })
        }


    },[] )


    return <div className={`award_item ${award.type}`}>
        <div className='name'>
            {imageurl? <div className="img">
                <img src={imageurl}></img>
                {award.rate ? <div className="rate">{award.rate}</div>:null}
                {tagName ? <div className="tag">{tagName}</div>:null}
            </div> : null}
            {name}
        </div>
        <div className='line'></div>
        <div className="value">{valueString}</div>
        </div>
}


function EventLandingPage(props:any){
    const [scripts,setScripts] = useState<any[]>([]);
    const [scriptBgs,setScriptBgs] = useState<any[]>([]);
    const { type } = useParams();
    const navigate = useNavigate();

    const [showAwardResult,setShowAwardResult] =  useState<boolean>(false)

    const [awardGroups,setAwardGroups] = useState<award[]>()
    const [opponentHeros,setOpponentHeros] = useState<any[]>([]);

    // 获取事件
    useEffect(  ()=> {
        const newScriptBgs = scriptBgs;

        Promise.all( scripts.map( async (script,index) => {
            if(script.template.image){
                const imageurl = await  axios.get(
                    `/api/v1/skibidi_toilet_card/image?name=${script.template.image}`
                ).then( response => {
                    if(response.statusText === 'OK'){
                        return response.data.url;
                    }
                } )
                newScriptBgs[index] = imageurl;
            }
        } ) ).then( () => {
            setScriptBgs(newScriptBgs);
        } )        

    },[scripts]);


    // 获取图片
    useEffect( ()=> {
        axios.get(
            `/api/v1/skibidi_toilet_card/getuserscripts?type=${type}`
        ).then( response => {
            if(response.statusText === 'OK'){
                setScripts(response.data.sort( (a:any,b:any) => a.id- b.id ))
            }
        } )
    },[]);

    // 指定了对手。
    // 请求对手的heroteam。
    useEffect( () => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const opponent = urlParams.get('opponent')

        if(opponent){
            axios.get(
                `/api/v1/skibidi_toilet_card/heroteam?userId=${opponent}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    setOpponentHeros(response.data)
                }
            } )
        }
    },[])



    // 进入事件
    const enterEvent = (e:any,script:any,scriptLog?:any)=>{
        if(script.type === 1 && !script.unlock){return;}




        if(script.type === 4){ //类型4的直接走兑换流程。
            axios.post( `/api/v1/skibidi_toilet_card/exchange`, {
                scriptID: script.id
            }).then( response => {
                console.log(response);
                if(response.statusText === 'Created' && response.data && response.data.awards){

                    const newAwardsArray:any[] = [];

                    response.data.awards.map( (award:any) => {
                        const findIndex = newAwardsArray.findIndex( e => e.type === award.type && e.id === award.id );
                        if(newAwardsArray[findIndex]){
                            newAwardsArray[findIndex].value += award.value;
                        }else{
                            newAwardsArray.push(award)
                        }
                    } )

                    setAwardGroups(newAwardsArray);
                    
                    setShowAwardResult(true);
                    
                    // navigate(`/event/${type}/${script.id}/${response.data.fight.id}/${response.data.scriptLog.id}`, { replace: true }); // <-- redirect
                }else if(response.statusText === 'Created' && response.data && response.data.message){
                    // todo: 添加整站统一的alert。
                    alert(response.data.message);
                }
            } );
        }else if( script.type === 3) { //匹配战斗
            if(scriptLog && scriptLog.id){
                navigate(`/event/${type}/${script.id}/${scriptLog.data.fights[0].id}/${scriptLog.id}`, { replace: true }); // <-- redirec
            }else{
                axios.post( `/api/v1/skibidi_toilet_card/matchfight`, {
                    scriptID: script.id
                }).then( response => {
                    console.log(response);
                    if(response.statusText === 'Created' && response.data && response.data.fight){
                        navigate(`/event/${type}/${script.id}/${response.data.fight.id}/${response.data.scriptLog.id}`, { replace: true }); // <-- redirect
                    }else if(response.statusText === 'Created' && response.data && response.data.message){
                        // todo: 添加整站统一的alert。
                        alert(response.data.message);
                    }
                } );
            }

        }else if(script.type === 6){ //匹配玩家的战斗

            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const opponent = urlParams.get('opponent')

            if(opponent){
                axios.post( `/api/v1/skibidi_toilet_card/matchfight`, {
                    scriptID: 14,
                    beChallengeId : opponent
                }).then( response => {
                    console.log(response);
                    if(response.statusText === 'Created' && response.data && response.data.fight){
                        navigate(`/event/6/14/${response.data.fight.id}/${response.data.scriptLog.id}`, { replace: true }); // <-- redirect
                    }else if(response.statusText === 'Created' && response.data && response.data.message){
                        // todo: 添加整站统一的alert。
                        alert(response.data.message);
                    }
                } );
            }else{
                alert('错误的参数')
            }
        }else{

            // 如果包含了scrciptLog 直接继续
            if(scriptLog && scriptLog.id){
                navigate(`/event/${type}/${script.id}/${scriptLog.data.fights[0].id}/${scriptLog.id}`, { replace: true }); // <-- redirec
            }else{
                // 创建事件
                axios.post( `/api/v1/skibidi_toilet_card/fight`, {
                    scriptID: script.id
                }).then( response => {
                    if(response.statusText === 'Created' && response.data && response.data.fight){
                        navigate(`/event/${type}/${script.id}/${response.data.fight.id}/${response.data.scriptLog.id}`, { replace: true }); // <-- redirect
                    }else if(response.statusText === 'Created' && response.data && response.data.message){
                        // todo: 添加整站统一的alert。
                        alert(response.data.message);
                    }
                } );
            }      
        }         
    } 


    const closeAwardResult = () => {
        setShowAwardResult(false);
    }

    return (
        <div id="eventLandingPage" style={{
            backgroundImage : "url('/assets/images/eventlanding_bg.jpg')"
        }}>
            <h2 className="title">
                <Link className="icon" to={'/'}>
                    <img src="/assets/icons/back.svg" alt="" />
                </Link>
                {eventName[Number(type)]}
                <div className="icon"></div>
            </h2>

            <div className="scripts_outer">
                {scripts.map( (script:any,index:number) => {
                    const cl = (script.unlock || type !== "1") ? 'script_container' : 'script_container unactive'

                    // 连接线的样式
                    let bridgeClass:string = '';
                    let hasbridge:boolean = false;
                    if(script.unlock && scripts[index+1] && scripts[index+1].unlock){
                        bridgeClass = 'bridge unlock2unlock';
                        hasbridge = true;
                    }else if(script.unlock && scripts[index+1] && !scripts[index+1].unlock){
                        bridgeClass = 'bridge unlock2lock';
                        hasbridge = true;
                    }else if(!script.unlock && scripts[index+1] && !scripts[index+1].unlock){
                        bridgeClass = 'bridge lock2lock';
                        hasbridge = true;
                    }

                    let lastScriptLog:any;
                    
                    if(script && script.scriptLogs && script.scriptLogs.length){
                        lastScriptLog = script.scriptLogs[ script.scriptLogs.length -1]
                    }

                    return <div className={cl} key={`script_${index}`} >
                        {type === "1" ? <div className="level">{`1-${script.id}`}</div> : null}

                        <div className="infos">
                            <div className="title">{script.template.title}</div>
                            <div className="gap"></div>
                            <div className="awards">
                                <div className="awards_title">可能获得</div>
                                <div className="awards_content">
                                    {script.awards.map( (award:any,award_index:number) => {
                                        let awards_name:string = "";
                                        if(award.type == "equipment" && !award.cnName){
                                            awards_name = "随机装备卡"
                                        }else if(award.type == "core" && !award.cnName){
                                            awards_name = "随机核心卡"
                                        }else if(award.type == "silicon"){
                                            awards_name = "硅晶"
                                        }else if(award.type == "hero"){
                                            awards_name = "随机英雄卡"
                                        }else if(award.cnName){
                                            awards_name = award.cnName
                                        }
                                        return <div className="awards_element" key={`script_${index}_award_${award_index}`}>
                                            {award.type == "silicon" ? <div className="place"><img src="/assets/icons/silicon.svg" alt="" /></div>: null}
                                            {award.image ? <div className="place"><img src={`https://oss.usecubes.cn/${award.image.url}?x-oss-process=image/resize,w_50,limit_0`} alt="" /></div> : null}
                                            {award.rare ? <div className="place">{award.rare}</div> : null}
                                            {award.tips ? <div className="tips">{award.tips}</div> : null}
                                            {award.rate ? <div className="rate">{award.rate}</div> : null}
                                            <div className="awards_name">{awards_name}</div>
                                        </div>
                                    } )}
                                </div>
                            </div>


                            {script.template.type !== 'once' ? <div className="state_text">
                                {(script.unlock || type !=="1") ? 
                                <><div className="buttons">
                                        { (lastScriptLog && lastScriptLog.result === "playing") ? <div className="start_game" onClick={ (e) => enterEvent(e,script,lastScriptLog)}>
                                            <div className="btn">
                                                <img src="/assets/icons/icon_continue.svg" alt="" />
                                                <span>继续</span>
                                            </div>
                                        </div> : null}
                                        <div className="gap"></div>

                                        {(lastScriptLog && lastScriptLog.result === "playing") ? <div className="start_game" onClick={ (e) => enterEvent(e,script)}>

                                           {script.template.hashrate ? <div className="tag"> <span>消耗算力：</span>
                                                <b>{script.template.hashrate}</b></div>: null}
                                            
                                            {script.template.silicon ? <div className="tag"> <span>消耗硅晶：</span>
                                                    <b>{script.template.silicon}</b></div>: null}

                                            <div className="btn">
                                                <img src="/assets/icons/icon_restart.svg" alt="" />
                                                <span>重开</span>
                                            </div>
                                            
                                        </div> : <div className="start_game" onClick={ (e) => enterEvent(e,script)}>


                                            {script.template.hashrate ? <div className="tag"> <span>消耗算力：</span>
                                                <b>{script.template.hashrate}</b></div>: null}
                                            
                                            {script.template.silicon ? <div className="tag"> <span>消耗硅晶：</span>
                                                    <b>{script.template.silicon}</b></div>: null}
                                            
                                            <div className="btn">
                                                <img src="/assets/icons/icon_enter.svg" alt="" />
                                                {script.template.startButton?<span>{script.template.startButton}</span>:<span>开启</span>}
                                            </div>

                                        </div>}
                                </div></> :
                                <>暂未解锁<img src="/assets/icons/icon_unlock.svg" alt="" /></>}
                            </div> : <div className="state_text">
                                <div className="buttons">
                                    {lastScriptLog && lastScriptLog.result == "win" ? <div className="start_game">
                                            已开启
                                        </div>: <>
                                        { (lastScriptLog && lastScriptLog.result === "playing") ? <div className="start_game" onClick={ (e) => enterEvent(e,script,lastScriptLog)}>
                                            <div className="btn">
                                                <img src="/assets/icons/icon_continue.svg" alt="" />
                                                <span>继续</span>
                                            </div>
                                        </div> : <div className="start_game" onClick={ (e) => enterEvent(e,script)}>
                                        {script.template.hashrate ? <div className="tag"> <span>消耗算力：</span>
                                            <b>{script.template.hashrate}</b></div>: null}

                                        {script.template.silicon ? <div className="tag"> <span>消耗硅晶：</span>
                                                <b>{script.template.silicon}</b></div>: null}

                                        <div className="btn">
                                            <img src="/assets/icons/icon_enter.svg" alt="" />
                                            {script.template.startButton?<span>{script.template.startButton}</span>:<span>开启</span>}
                                        </div>
                                    </div>}</>}
                                    
                                </div>
                            </div>}
                            

                        </div>

                        {script.heros.length > 0 ? <div className="heros">
                            {script.heros.map( (hero :any) => {
                                const avatar = hero.image ? `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_200,limit_0` :  `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_200,limit_0`
                                return <div className='heroAvatar' key={`hero_avatar_${hero.id}`}>
                                    <img src={avatar} alt="" />
                                </div>
                            }) }
                        </div>:null}

                        {opponentHeros.length > 0 ? <div className="heros">
                            {opponentHeros.map( (hero :any) => {
                                const avatar = hero.image ? `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_200,limit_0` :  `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_200,limit_0`
                                return <div className='heroAvatar' key={`hero_avatar_${hero.id}`}>
                                    <img src={avatar} alt="" />
                                </div>
                            }) }
                        </div>:null}

                        {script.type === 3 ? <div className="heros">
                            {Array(3).fill(0).map( (e,index:number) => {
                                return <div className='heroAvatar' key={`hero_avatar_${index}`}>
                                    <img src="/assets/images/hero_place.png" alt="" />
                                </div>
                            }) }
                        </div>:null}

                        {scriptBgs[index] ? <div className="heros">
                            <div className="heroBg">
                                <img src={`https://oss.usecubes.cn/${scriptBgs[index]}?x-oss-process=image/resize,w_300,limit_0`} alt="" />
                            </div>
                        </div>:null}

                        { hasbridge ? <div className={bridgeClass}></div> : null}
                    </div>
                } )}
            </div>

            {showAwardResult ? <div className='awardResultOuter'>
                <div className="bg"></div>
                <div className="resultInner">
                    <div className="resultWrap">
                        <div className="title">获得装备</div>

                        {awardGroups && awardGroups.map( (award:any,index:number) => {
                            return <div key={`award_item_${index}`}><AwardItem award={award}></AwardItem></div>
                        })}

                        <div className="gap"></div>

                        <div className="btns">
                            <div className="accept_button" onClick={closeAwardResult}>确认</div>
                        </div>

                    </div>
                </div>
            </div> : null}

     
        </div>
    )
}

export default EventLandingPage