import { useRef, useState ,useEffect, JSXElementConstructor, ReactElement, ReactPortal} from 'react';
import axios from 'axios';

import { useParams,redirect,useNavigate  } from 'react-router-dom';


import { IRefPhaserGame, PhaserGame } from './../../game/PhaserGame';
import { MainMenu } from './../../game/scenes/MainMenu';
import './index.less';
import { Transform } from 'stream';


// ------------ TypeWriter --------------- //

interface TypewriterProps {
    /** The text to display inside the button */
    words: [{
        text : string;
        attr : string;
    }];
    // 播放速度
    speed : number;
    // 效果
    // effect : 'shake' | null ; 
  }
  


function Typewriter({ words,speed }: TypewriterProps ) {
    const [texts,setTexts] = useState<any[]>();

    useEffect( () => {
            
        let t:any[]=[];
        let word_index = 0;
        // let isEmoji:boolean = false;

        for(var i = 0; i < words.length ; i++){
            const title = words[i].text;
            const effect = words[i].attr;
            const gap_ms = 1000/speed;

            for(var n=0;n<title.length;n++){
                // console.log(i*n)
                word_index++;
                const style = {
                    animationDelay : `${(word_index)*gap_ms}ms`
                }
    
                t.push(<em className={`typewrite_letter`} key={`word_${i}_${n}`} style={style} >{ 
                    effect ? <span className={effect} style={style}>{title[n]}</span> : title[n]
                    }</em>);
            }
        }

        setTexts(t);
    },[words])

    return (
      <p>{texts}</p>
    );
  }

  interface HeroCardProps {
    hero: any;
    positions : any; 
    index : number;
    effects : any[];
    type : 'beChallenged' | 'challenged';
}
  

//   英雄卡片
function HeroCard(  { hero,positions,index,effects,type} : HeroCardProps ){

    let image_url = '';
    if(hero.image){
        image_url = `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_300,limit_0`;
    }else if(hero.category && hero.category.image){
        image_url = `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_300,limit_0`;
    }else{
        image_url = '';
    }

    let name = '';
    if(hero.cnName){
        name = hero.cnName;
    }else if(hero.category && hero.category.cnName){
        name = hero.category.cnName;
    }else{
        name = '';
    }

    const style = {
        left :  type === 'beChallenged' ?  `${positions.beChallenged[index].left}px` :  `${positions.challenge[index].left}px`,
        top :  type === 'beChallenged' ?  `${positions.beChallenged[index].top}px` :  `${positions.challenge[index].top}px`,
        zIndex : hero.data.hp ? (type === 'beChallenged' ? positions.beChallenged[index].zIndex :  positions.challenge[index].zIndex) : 90,
        transitionDey : `${100+index*100}ms`,
        filter : hero.data.hp ? '' : 'grayscale(1)'
    }

    let cl = `card hero`;
    const hero_effects = effects.filter( e => e.id === hero.id );

    hero_effects.forEach( eff => {
        if(eff.type === 'hp_reduce' || eff.type === 'defense_reduce'){
            cl += ' damage';
        }else if(eff.type === 'hp_increase' || eff.type === 'defense_increase'){
            cl += ' increase';
        }
    })

    return <div className={cl} style={style}>
        
        <div className="hero_main">
            {image_url ? <img  className='bg' src={image_url} alt=''></img> : null}
            {name ? <div className="name" >
                {name}    
            </div> : null}
            <div className="hero_state">
                {/* hp */}
                <div className="hero_state_block">
                    <div className="state_block_name">HP</div>
                    <div className="state_block_progress">
                        <div className="bg"></div>
                        <div className="value" style={{backgroundColor : "#E42626", width:`${hero.data.hp/hero.data.maxHp*100}%`}}></div>
                    </div>
                    <div className="state_block_value">{hero.data.hp}</div>
                </div>
                {/* defense */}
                <div className="hero_state_block">
                    <div className="state_block_name">
                        <img src="/assets/icons/shield.svg" alt="" />
                    </div>
                    <div className="state_block_progress">
                        <div className="bg"></div>
                        <div className="value" style={{backgroundColor : "#2688E4", width:`${hero.data.defense/hero.data.maxHp*100}%`}}></div>
                    </div>
                    <div className="state_block_value">{hero.data.defense}</div>
                </div>

                {hero_effects.map( (effect,effect_index) => {
                    return <div key={`hero_${hero.id}_${effect_index}`} className={effect.type}>{`${effect.value>0?'+':''}${effect.value}`}</div>
                } )}
            </div>
        </div>

        {hero.data.buffs.length > 0 ? <>
                <div className="buff_links">
                    <div className="link_icon_left">
                        <img src="/assets/icons/link.png" alt="" />
                    </div>
                    <div className="link_icon_right">
                        <img src="/assets/icons/link.png" alt="" />
                    </div>
                </div>
                <div className="hero_buffs">
                    { hero.data.buffs.map( (buff:any,index:number) => {
                        const image_url = `https://oss.usecubes.cn/${buff.skill.category.image.url}?x-oss-process=image/resize,w_50,limit_0`;
                        return <div key={`hero_buff_${hero.id}_${index}`}>
                        <div className="hero_buff" >
                        <img src={image_url} alt="" />
                        {buff.executeCount ? <div className="number">
                            <em>{buff.executeCount}</em>
                        </div>:null}
                    </div></div>
                    } )}
        </div> </> : null}
    </div>
}

interface AwardItemProps {
    award : {
        hash? : string;
        type : string;
        value : number;
        rate? : string;
    }

}

function AwardItem ( {award} : AwardItemProps  ){
    const [name,setName] = useState<string>()
    const [imageurl,setImageUrl] = useState<string>()
    const [valueString,setValueString] = useState<string>()
    const [tagName,setTagName] = useState<string>()


    useEffect( ()=> {
        if(award.type === "silicon"){
            setImageUrl(`/assets/icons/silicon.svg` )
            setName('硅晶');
            setValueString(award.value.toString())
        }

        if(award.type === "equipment"){

            setValueString(`${( (award.value).toFixed(2)).toString()}`)
            setTagName("装备卡")

            axios.get(`/api/v1/skibidi_toilet_card/equipment?hash=${award.hash}`)
            .then(response => {
                if(response.statusText === "OK"){                    
                    setImageUrl(`https://oss.usecubes.cn/${response.data.equipment.category.image.url}?x-oss-process=image/resize,w_80,limit_0` )
                    setName(response.data.equipment.category.cnName)
                }
            })
        }

        if(award.type === "core"){
            setValueString(`${( (award.value).toFixed(2)).toString()}`)
            setTagName("核心卡")
            axios.get(`/api/v1/skibidi_toilet_card/core?hash=${award.hash}`)
            .then(response => {
                if(response.statusText === "OK"){                    
                    setImageUrl(`https://oss.usecubes.cn/${response.data.core.category.image.url}?x-oss-process=image/resize,w_80,limit_0` )
                    setName(response.data.core.category.cnName)
                }
            })
        }

        if(award.type === "hero"){
            setValueString(`${( (award.value).toFixed(2)).toString()}`)
            setTagName("英雄卡")
            axios.get(`/api/v1/skibidi_toilet_card/herobyhash?hash=${award.hash}`)
            .then(response => {
                if(response.statusText === "OK"){     
                    const hero = response.data.hero;               
                    setImageUrl(  hero.image ? `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_80,limit_0` :  `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_80,limit_0`)
                    setName(hero.category ? hero.category.cnName : '')
                }
            })
        }




    },[] )


    return <div className={`award_item ${award.type}`}>
        <div className='name'>
            {imageurl? <div className="img">
                <img src={imageurl}></img>
                {award.rate ? <div className="rate">{award.rate}</div>:null}
                {tagName ? <div className="tag">{tagName}</div>:null}
            </div> : null}
            {name}
        </div>
        <div className='line'></div>
        <div className="value">{valueString}</div>
        </div>
}

  interface FightLog {
    id: number,
    step : number,
    state : {
        point : number;
        beChallenged : {
            team : [any]
        };
        challenge : {
            team : [any]
        };
        buffSkills : [any];
        turn : 'challenge' | 'beChallenged';
        playing : any | null;
    }
}

interface Reource {
    name : string,
    url : string,
    type : string
}


interface Event{
    fights : {
        bgm : string
    }[];

    scripts : any[];

    awardGroups : {
        awards : {
            type : string;
            value : number;
            hash? : string;
        }[]
    }[];


}

function EventPage()
{

    const { type,fightid,scriptlogid } = useParams();

    const navigate = useNavigate();


    // 用来控制页面是对话还是游戏。
    const [pageState,setPageState] = useState<'dialogueState'|'gameState'>('dialogueState');
    // The sprite can only be moved in the MainMenu Scene
    const [event,setEvent] = useState<Event>();

    const [step,setStep] = useState<number>(9);

    const [script,setScript] = useState<any>();
    const [background, setBackground] = useState<string>();
    const [dialogue,setDialogue] = useState<any>({
        words : [{
            text : '',
            attr : ''
        }],
        speed : 50
    });
    const [meetHeros, setMeetHeros] = useState<any[]>([]);
    const [meetHerosPosition, setMeetHerosPosition] = useState<'challenge'|''>('')

    const [checkedText,setCheckedText]  = useState<string>('');

    //  References to the PhaserGame component (game and scene are exposed)
    const phaserRef = useRef<IRefPhaserGame | null>(null);
    // const [fightID,setFightID] = useState(null);
    const [fightLog,setFightLog] = useState<FightLog>();

    const [resource,setResource] = useState<Reource[]>([]);

    const [loadComplete,setLoadComplete] = useState(false); 
    const [needLoad,setNeedLoad] = useState(false); 

    const [bgm, setBGM] = useState<HTMLAudioElement>();

    const [touchStartY,setTouchStartY] = useState<number>()
    const [touchDeltaY,setTouchDeltaY] = useState<number>()

    let globalTimer: ReturnType<typeof setTimeout>;

    const [positions, setPositions] = useState({
        beChallenged : [{
            top: 75,
            left: 15,
            zIndex : 100
        },{
            top: 75,
            left:15,
            zIndex : 99
        },{
            top: 75,
            left: 15,
            zIndex : 98
        }],
        challenge : [{
            top: 295,
            left : 15,
            zIndex : 100
        },{
            top: 295,
            left:15,
            zIndex : 99
        },{
            top: 295,
            left: 15,
            zIndex : 98
        }],
        skills : [{
            top: 0,
            left: 0,
            zIndex : 100
        },{
            top: 0,
            left: 0,
            zIndex : 99
        },{
            top: 0,
            left: 0,
            zIndex : 98
        },{
            top: 0,
            left: 0,
            zIndex : 97
        },{
            top: 0,
            left: 0,
            zIndex : 96
        },{
            top: 0,
            left: 0,
            zIndex : 95
        }]
    });


    const [showWinResult,setShowWinResult] = useState<boolean>(false)
    const [showLoseResult,setShowLoseResult] = useState<boolean>(false)

    const [showAwardResult,setShowAwardResult] =  useState<boolean>(false)

    // 比较数据不同得到需要播放的特效
    const [effects,setEffects] = useState<any[]>([])

    const [selectedSkillIndex,setSeletedSkillIndex] = useState<number>();

    // 倒计时
    const [timerSeconds,setTimerSeconds] = useState<number>(0);
    const [MT,setMT] = useState<boolean>(false);

    const [turnState,setTurnState] = useState<string>()

    let clickGapTimer: ReturnType<typeof setTimeout>;

    // 整合需要载入图片的资源。
    useEffect( () =>{
        const res = resource || {}
        if(fightLog){
            const getHeroImages = function ( team:any[], type: string ){
                for (const hero of team) {
                    const id = hero.id;

                    const existResource = resource?.find( r => r.name === `hero_${id}` );
                    if(existResource){
                        // console.log(existResource.name,'has exsit');
                    }else {
                        // hero的图片如果没有的话，使用category的图片
                        let url = '';
                        if(hero.image){
                            url = `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_600,limit_0`;
                        }else if(hero.category && hero.category.image){
                            url = `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_600,limit_0`;
                        }else{
                            url = '';
                        }
    
                        if(url){
                            res.push({
                                name : `hero_${id}`,
                                url ,
                                type 
                            })
                        }                        
                    }
                }
            }
            const getEquipmentImages = function(){
                for(const buffSkill of fightLog.state.buffSkills){
                    const name = buffSkill.skill.category.cnName;
                    // 暂时不处理没有ID的。
                    if(name){
                        const existResource = resource?.find( r => r.name === `equipment_${name}`);
                        if(existResource){

                        }else {
                            // hero的图片如果没有的话，使用category的图片
                            let url = '';
                            if(buffSkill.skill.category && buffSkill.skill.category.image){
                                url = `https://oss.usecubes.cn/${buffSkill.skill.category.image.url}?x-oss-process=image/resize,w_200,limit_0`;
                            }else{
                                url = '';
                            }
                            if(url){
                                res.push({
                                    name : `equipment_${name}`,
                                    url ,
                                    type : 'equipment' 
                                })
                            }                        
                        }
                    }
                }
            }

            getHeroImages(fightLog.state.beChallenged.team,'npc');
            getHeroImages(fightLog.state.challenge.team,'character');
            getEquipmentImages();
            setResource(res);
            setNeedLoad(true);
            // 设置位置。
            if(fightLog.state.turn === 'challenge'){
                const window_width = window.innerWidth;
                const window_height = window.innerHeight;
                setPositions({
                    beChallenged : [{
                        top: 75,
                        left: 15,
                        zIndex : 100
                    },{
                        top: 60,
                        left:15 + (window_width-154)/2,
                        zIndex : 99
                    },{
                        top: 45,
                        left: 15 + (window_width-154),
                        zIndex : 98
                    }],
                    challenge : [{
                        top: 295,
                        left : 15,
                        zIndex : 100
                    },{
                        top: 310,
                        left: 15 + (window_width-154)/2,
                        zIndex : 99
                    },{
                        top: 325,
                        left: 15 + (window_width-154),
                        zIndex : 98
                    }],
                    skills : [{
                        top : window_height - 180,
                        left : 15,
                        zIndex : 100
                    },{
                        top : window_height - 180,
                        left : 15 + (window_width-154)/6,
                        zIndex : 99
                    },{
                        top : window_height - 180,
                        left : 15 + (window_width-154)/6*2,
                        zIndex : 98
                    },{
                        top : window_height - 180,
                        left : 15+ (window_width-154)/6*3,
                        zIndex : 97
                    },{
                        top : window_height - 180,
                        left : 15+ (window_width-154)/6*4,
                        zIndex : 96
                    },{
                        top : window_height - 180,
                        left : 15+ (window_width-154)/6*5,
                        zIndex : 95
                    }]
                })
            }else{
                const window_width = window.innerWidth;
                setPositions({
                    beChallenged : [{
                        top: 240,
                        left: 15,
                        zIndex : 100
                    },{
                        top: 225,
                        left:15 + (window_width-154)/2,
                        zIndex : 99
                    },{
                        top: 210,
                        left: 15 + (window_width-154),
                        zIndex : 98
                    }],
                    challenge : [{
                        top: 460,
                        left : 15,
                        zIndex : 100
                    },{
                        top: 475,
                        left: 15 + (window_width-154)/2,
                        zIndex : 99
                    },{
                        top: 490,
                        left: 15 + (window_width-154),
                        zIndex : 98
                    }],
                    skills : [{
                        top : 20,
                        left : 15,
                        zIndex : 100
                    },{
                        top : 20,
                        left : 15 + (window_width-154)/6,
                        zIndex : 99
                    },{
                        top : 20,
                        left : 15 + (window_width-154)/6*2,
                        zIndex : 98
                    },{
                        top : 20,
                        left : 15+ (window_width-154)/6*3,
                        zIndex : 97
                    },{
                        top : 20,
                        left : 15+ (window_width-154)/6*4,
                        zIndex : 96
                    },{
                        top : 20,
                        left : 15+ (window_width-154)/6*5,
                        zIndex : 95
                    }]
                })
            }

            if(fightLog.state.turn === 'beChallenged' || fightLog.state.point === 0){
                startAutoFight();
                setTimerSeconds(0);
                setTurnState('beChallenged')
            }else if(fightLog.state.turn === 'challenge'){
                // 首次转换设置倒计时
                if(turnState === 'beChallenged' || !turnState){
                    setTimerSeconds(6);
                }else{
                    if(!MT){
                        startAutoFight();
                        setTimerSeconds(0);
                    }
                }
                setTurnState('challenge')
            }
        }

    },[fightLog]);

    useEffect( () => {
        setLoadComplete(false);
        // TODO: 有时候场景会没有准备好。这里暂时用延时代替一下。囧
        setTimeout( () => {
            if(phaserRef.current &&  phaserRef.current.scene)
                {    
                    const scene = phaserRef.current.scene as MainMenu;
                    if (scene)
                    {
                        if(resource && resource.length){
    
                            resource.forEach(r => {
                                scene.load.image(r.name,r.url);
                            })
                            scene.load.start()
                            scene.load.once(Phaser.Loader.Events.COMPLETE, () => {
                                setLoadComplete(true);
                                setNeedLoad(false);
                            })
                        }
                    }
                }
        },1000)

    },[resource,needLoad]);

    useEffect( () => {
        if(fightLog && loadComplete){
            if(phaserRef.current)
                {     
                    const scene = phaserRef.current.scene as MainMenu;
                    if (scene)
                    {
                        scene.renderInit(fightLog);
                    }
                }
        }
    },[fightLog,loadComplete])

    useEffect( () => {
        if(fightLog?.state.turn === "challenge" && timerSeconds > 0 && MT===false){
            if(globalTimer !== undefined){clearTimeout(globalTimer)}
            globalTimer =  setTimeout(() => {
                if(timerSeconds == 1){
                    startAutoFight();
                }
                setTimerSeconds(timerSeconds-1);
            },1000)
        }
    },[timerSeconds] )

    const startAutoFight = async (forceChangeTurn?:boolean) => {
        if(!fightid){return}
        if(pageState !== "gameState"){return;}
        let changeTurn : boolean = false;
        if(!fightLog?.state.point ){
            changeTurn = true;
        }
        if(forceChangeTurn){
            changeTurn = true;
        }

        axios.post(
            `/api/v1/skibidi_toilet_card/fight/update`,
            {
                id : fightid,
                pushData : {
                    autoFight : true,
                    changeTurn
                }
            }
        ).then( response => {
            if(response.statusText === 'Created'){

                const newLog = response.data.log;
                // 比较下log的区别，用来播放动画
                compareLogDiff(newLog)
                // 需要延时2s，来播放动画效果
                setTimeout( () => {
                    setFightLog(newLog);
                    // 清空效果
                    setEffects([])
                },1000)


                if(response.data.result === "win" || response.data.result === "lose"){
                    setTimeout( ()=>{
                        setPageState('dialogueState');
                        bgm?.pause(); 
    
                        const nextStep  = event?.scripts.findIndex( (x:any) => x.key === response.data.result );
                        if(nextStep){
                            setStep(nextStep);
                        }else{
                            setStep( step +1);
                        }

                    },3000)  
                }
            }
        } )
    }

    const startManualFight = (uid:string) => {
        if(!fightid){return}
        if(pageState !== "gameState"){return;}
        let changeTurn : boolean = false;
        if(!fightLog?.state.point ){
            changeTurn = true;
        }

        axios.post(
            `/api/v1/skibidi_toilet_card/fight/update`,
            {
                id : fightid,
                pushData : {
                    playingUid : uid,
                    changeTurn
                }
            }
        ).then( response => {
            if(response.statusText === 'Created'){
                if(response.data.result === "win" || response.data.result === "lose"){
                    setPageState('dialogueState');
                    bgm?.pause(); 

                    const nextStep  = event?.scripts.findIndex( (x:any) => x.key === response.data.result );
                    if(nextStep){
                        setStep(nextStep);
                    }else{
                        setStep( step +1);
                    }
                }else{
                    const newLog = response.data.log;
                    // 比较下log的区别，用来播放动画
                    compareLogDiff(newLog)
                    // 需要延时2s，来播放动画效果
                    setTimeout( () => {
                        setFightLog(newLog);
                        // 清空效果
                        setEffects([])
                    },1000)
                }
            }
        } )

    }

  
    const extractAttributesAndText = (text:string) => {
        // 将 [ 替换成 ]
        const textRemoveSymoble = text.replaceAll('[',']');
        // 分隔"]",并去空
        const textArr = textRemoveSymoble.split(']').filter(e => e);
        const formatTextArr = [];
        // 提取属性。
        for(const t in textArr){
            if(textArr[t].includes(':')){
                formatTextArr.push({
                    text: textArr[t].split(':')[1],
                    attr : textArr[t].split(':')[0]
                })
            }else{
                formatTextArr.push({
                    text: textArr[t],
                    attr : ''
                })
            }
        }

        return formatTextArr;
    }

    // 请求事件脚本
    useEffect( () => {
        const id = scriptlogid;
        if(id){
            axios.get(
                `/api/v1/skibidi_toilet_card/scriptlog?id=${id}`,{
                    responseEncoding: 'utf8mb4',
                    responseType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            ).then( response => {
                if(response.statusText === 'OK'){
                    const returnEvent = response.data.data;
                    // 查看fight id 是否一致
                    const returnFight = returnEvent.fights[0];

                    if(returnFight.id !== Number(fightid)  ){
                        alert('无法匹配对战信息'); 
                        backtohome();
                    }else{
                        if(response.data.runningIndex){
                            // 这里需要顺带设置背景。
                            setClosestBG(response.data.runningIndex,returnEvent)
                            setStep(response.data.runningIndex)
                        }else{
                            setStep(0);
                        }

                        setEvent(returnEvent);
                        // 这里先载入bgm
                        axios.get(
                            `/api/v1/skibidi_toilet_card/image?name=${returnFight.bgm}`
                        ).then(response => {
                            if(response.statusText === 'OK'){
                                setBGM( new Audio(`https://oss.usecubes.cn/${response.data.url}`));
                            }
                        });
                    }
                }
            } )
        }
    },[])
      

    // 监听step变化
    useEffect( () => {
        if(event?.scripts){
            const script = event.scripts[step];
            setScript(script);
        }
    },[step,event])

    // 当前脚本
    useEffect( () => {
        // 载入脚本
        setDialogue({
            words : '',
            type : 'hide',
            avatar : '',
            name: ''
        });

        if(!script){return;}

        if(script.type === 'background'){
            const name = script.name;
            axios.get(
                `/api/v1/skibidi_toilet_card/image?name=${name}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    setBackground(`https://oss.usecubes.cn/${response.data.url}`);

                    setTimeout( () => {
                        setStep(step+1);
                    }, 1000 )
                }
            } )
        }

        // talk
        if(script.type === 'talk'){ 
            setTimeout(async ()=>{
                const formatTextArr = extractAttributesAndText(script.text);
                let avatar:string = '';
                let name: string = '';

                if(script.talkingId){
                    await axios.get(
                        `/api/v1/skibidi_toilet_card/hero?id=${script.talkingId}`
                    ).then( response => {
                        if(response.statusText === 'OK'){
                            // TODO: 有可能会报错。
                            avatar = response.data.image ? `https://oss.usecubes.cn/${response.data.image.url}?x-oss-process=image/resize,w_200,limit_0` :  `https://oss.usecubes.cn/${response.data.category.image.url}?x-oss-process=image/resize,w_200,limit_0`
                            // TODO: 
                            name = response.data.cnName ? response.data.cnName : response.data.category.cnName;
                        }
                    } )
                }

                setDialogue({
                    words : formatTextArr,
                    avatar,
                    name,
                    typeSpeed : script.speed || 50,
                    type : script.talking || 'show',
                    options : script.options || null,
                    option_show_delay : script.text.length * 1000/script.speed + 500  
                })
            },200)
        }

        // meet
        if(script.type === 'meet'){ 
            setTimeout(async ()=>{

                const query_urls:string[] = [];
                const meetHeros:any[] = [];

                script.query.map( (q: any) => {
                    query_urls.push(`/api/v1/skibidi_toilet_card/hero?id=${q.id}`);
                })

                await axios.all(query_urls.map((query_url) => axios.get(query_url))).then(
                    (responses:any) => {
                        responses.map( (response:any) => {
                            if(response.statusText === 'OK' && response.data){
                                // TODO: 有可能会报错。
                                meetHeros.push(response.data);
                            }
                        } )
                        
                    }
                );
                setMeetHeros(meetHeros);

                if(script.position){
                    setMeetHerosPosition(script.position);
                }else{
                    setMeetHerosPosition('');
                }

                setTimeout( () => {
                    setStep(step+1);
                }, 1000 );
            },200)
        }


        if(script.type === 'fight'){
            axios.get( `/api/v1/skibidi_toilet_card/fight?challengeId=${fightid}`)
            .then( (response) => 
                {
                if(response.statusText === 'OK' && response.data){
                    // 跳转到战斗
                    setFightLog(response.data.log);
                    setPageState('gameState');

                    // 第一次还是要设置一下位置，不然会偏移
                    if(response.data.log.state.turn === 'challenge'){
                        const window_width = window.innerWidth;
                        const window_height = window.innerHeight;
                        setPositions({
                            beChallenged : [{
                                top: 75,
                                left: 15,
                                zIndex : 100
                            },{
                                top: 60,
                                left:15 + (window_width-154)/2,
                                zIndex : 99
                            },{
                                top: 45,
                                left: 15 + (window_width-154),
                                zIndex : 98
                            }],
                            challenge : [{
                                top: 295,
                                left : 15,
                                zIndex : 100
                            },{
                                top: 310,
                                left: 15 + (window_width-154)/2,
                                zIndex : 99
                            },{
                                top: 325,
                                left: 15 + (window_width-154),
                                zIndex : 98
                            }],
                            skills : [{
                                top : window_height - 180,
                                left : 15,
                                zIndex : 100
                            },{
                                top : window_height - 180,
                                left : 15 + (window_width-154)/6,
                                zIndex : 99
                            },{
                                top : window_height - 180,
                                left : 15 + (window_width-154)/6*2,
                                zIndex : 98
                            },{
                                top : window_height - 180,
                                left : 15+ (window_width-154)/6*3,
                                zIndex : 97
                            },{
                                top : window_height - 180,
                                left : 15+ (window_width-154)/6*4,
                                zIndex : 96
                            },{
                                top : window_height - 180,
                                left : 15+ (window_width-154)/6*5,
                                zIndex : 95
                            }]
                        })
                    }else{
                        const window_width = window.innerWidth;
                        const window_height = window.innerHeight;
                        setPositions({
                            beChallenged : [{
                                top: 240,
                                left: 15,
                                zIndex : 100
                            },{
                                top: 225,
                                left:15 + (window_width-154)/2,
                                zIndex : 99
                            },{
                                top: 210,
                                left: 15 + (window_width-154),
                                zIndex : 98
                            }],
                            challenge : [{
                                top: 460,
                                left : 15,
                                zIndex : 100
                            },{
                                top: 475,
                                left: 15 + (window_width-154)/2,
                                zIndex : 99
                            },{
                                top: 490,
                                left: 15 + (window_width-154),
                                zIndex : 98
                            }],
                            skills : [{
                                top : 20,
                                left : 15,
                                zIndex : 100
                            },{
                                top : 20,
                                left : 15 + (window_width-154)/6,
                                zIndex : 99
                            },{
                                top : 20,
                                left : 15 + (window_width-154)/6*2,
                                zIndex : 98
                            },{
                                top : 20,
                                left : 15+ (window_width-154)/6*3,
                                zIndex : 97
                            },{
                                top : 20,
                                left : 15+ (window_width-154)/6*4,
                                zIndex : 96
                            },{
                                top : 20,
                                left : 15+ (window_width-154)/6*5,
                                zIndex : 95
                            }]
                        })
                    }
                    
                    bgm?.play(); 
                }
            })
            .catch(function (error) {
                console.log(error);
            });
        }

        // 关闭
        if(script.type === 'close'){
            if(script.result === 'win'){
                setShowWinResult(true);
            }
            if(script.result === 'lose'){
                setShowLoseResult(true);
            }

            // 一次性领取类，无战斗，不要更改结果了。
            if(type !== '5'){
                axios.put(`/api/v1/skibidi_toilet_card/changescriptresult`,{
                    id : Number(scriptlogid)
                }).then(response => {
                    
                })
            }
            
        }

        if(script.type === 'bgm'){
            // TODO: 暂时先直接播放。
            bgm?.play();  
            setStep(step+1);
        }


        if(script.type === "award"){
            setShowAwardResult(true)
        }
    },[script,event])


    useEffect( () => {
        // 推送step变化。
        if(step){
            axios.put(`/api/v1/skibidi_toilet_card/changescriptlogstep`,{
                step,
                id : Number(scriptlogid)
            }).then(response => {
                // clearTimeout(clickGapTimer);
                // console.log(response)
            })
        }
    } ,[step])

    const setClosestBG = async (jumpStep:number,returnEvent:any) => {
        const scripts = returnEvent.scripts;
        let bg_script_index:number = -1;
        let meet_script_index:number = -1;

        await Promise.all(scripts.map( (s:any,i:number)=>{
            if(s.type === "background" && i <= jumpStep){
                bg_script_index = i;
            }
            if(s.type==="meet" && i <= jumpStep){
                meet_script_index = i;
            }
        } ))
        
        const bg_script = bg_script_index !== -1 ? scripts[bg_script_index] : null;
        const meet_script = meet_script_index !== -1 ? scripts[meet_script_index] : null;

        // const bg_script = scripts.findLast( (s:any,index:number) => ( s.type==="background" && index <= jumpStep ));
        // const meet_script = scripts.findLast( (s:any,index:number) => ( s.type==="meet" && index <= jumpStep ));
        
        // debugger;
        const name = bg_script.name;

        if(bg_script){

            axios.get(
                `/api/v1/skibidi_toilet_card/image?name=${name}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    setBackground(`https://oss.usecubes.cn/${response.data.url}`);
                }
            } )
        }

        if(meet_script){


            setTimeout(async ()=>{

                const query_urls:string[] = [];
                const meetHeros:any[] = [];
    
                meet_script.query.map( (q: any) => {
                    query_urls.push(`/api/v1/skibidi_toilet_card/hero?id=${q.id}`);
                })
    
                await axios.all(query_urls.map((query_url) => axios.get(query_url))).then(
                    (responses:any) => {
                        responses.map( (response:any) => {
                            if(response.statusText === 'OK' && response.data){
                                // TODO: 有可能会报错。
                                meetHeros.push(response.data);
                            }
                        } )
                        
                    }
                );
                setMeetHeros(meetHeros);
                if(meet_script.position){
                    setMeetHerosPosition(meet_script.position);
                }else{
                    setMeetHerosPosition('');
                }
            },200)
        }
    }

    const addStep = ()=>{

        console.log('addStep')
        // 限制不能连点2次。 
        if(dialogue.options){
            return;
        }   

        if(event?.scripts){
            if(step+1 > event.scripts.length - 1){
                return;
            }
            const currentScript = event.scripts[step];
            console.log(currentScript,step)
            // 对话、战斗、背景、遇见、背景乐不能跳过。
            if(currentScript.options || currentScript.type === 'fight' || currentScript.type === 'background' || currentScript.type === 'meet' || currentScript.type === 'bgm'){
                setScript(currentScript)
                return;
            }else{
                setStep(step+1);
            }
        }
    }


    const onOptionChanged = (checkedText: string,jumpKey:string)=>{
        setCheckedText(checkedText);

        setTimeout( () => {
            if(jumpKey){
                const nextStep  = event?.scripts.findIndex( (x:any) => x.key ===jumpKey);
                if(nextStep){
                    setStep(nextStep);
                }else{

                    setStep(step+1);
                }
            }else{

                setStep(step+1);
            }

        }, 1000 )
    }


    const compareLogDiff = (newFightLog:FightLog) => {

        const change_effects:any[] = [];

        // 先整理出减血的英雄&数值
        const origin_beChallenged_team = fightLog?.state.beChallenged.team;
        const new_beChallenged_team = newFightLog.state.beChallenged.team;

        const origin_challenge_team = fightLog?.state.challenge.team;
        const new_challenge_team = newFightLog.state.challenge.team;
        // 合并2组英雄，方便维护
        const origin_heros = origin_beChallenged_team?.concat(origin_challenge_team);
        const new_heros = new_beChallenged_team.concat(new_challenge_team);

        // console.log(origin_heros,new_heros)

        // 英雄的数值变化。
        origin_heros?.forEach( (hero) => {
            const newHero = new_heros.find( e => e.id === hero.id);
            // hp change
            if(newHero.data.hp < hero.data.hp){
                change_effects.push({
                    id : hero.id,
                    type : 'hp_reduce',
                    value : newHero.data.hp - hero.data.hp
                })
            }else if(newHero.data.hp > hero.data.hp){
                change_effects.push({
                    id : hero.id,
                    type : 'hp_increase',
                    value : newHero.data.hp - hero.data.hp
                })
            }

            // defense change
            if(newHero.data.defense < hero.data.defense){
                change_effects.push({
                    id : hero.id,
                    type : 'defense_reduce',
                    value : newHero.data.defense - hero.data.defense
                })
            }else if(newHero.data.defense > hero.data.defense){
                change_effects.push({
                    id : hero.id,
                    type : 'defense_increase',
                    value : newHero.data.defense - hero.data.defense
                })
            }
        } )

        // 释放的技能

        if(newFightLog.state.playing){
            change_effects.push({
                id : newFightLog.state.playing.uid,
                type : 'skill_release',
                value : ''
            })

        }

        setEffects(change_effects)
    }

    const backtohome = () => {
        navigate(`/`, { replace: true }); // <-- redirect
    }

    // 接受奖励
    const acceptAward = () => {
        axios.put(`/api/v1/skibidi_toilet_card/acceptaward`,{
            scriptlogid : Number(scriptlogid)
        }).then(response => {
            // 关闭弹窗
            // 增加step
            // console.log(response)
            if(response.statusText === "OK"){
                setShowAwardResult(false)
                addStep()
            }
        })
    }

    const startSeletcSkill = (e:any) => {
        setTouchStartY(e.touches[0].pageY);
        selectSkill(e);
    }

    const selectSkill = (e:any) => {
        if(fightLog?.state.point === 0 || fightLog?.state.turn === "beChallenged"){return;}


        if( fightLog?.state.buffSkills && fightLog?.state.buffSkills.length ){
            // 开始手动
            setMT(true);

            const x = e.touches[0].pageX;
            const y = e.touches[0].pageY;
            
            const deltaY = touchStartY ? y - touchStartY : 0;

            // 只有稍微移动一点距离才可以选中 
            if(deltaY > -10){
                let get_index ;
                positions.skills.map( (skill,skill_index) => {if(  x > skill.left && skill_index < fightLog?.state.buffSkills.length){
                    get_index = skill_index;
                }} );
                setSeletedSkillIndex(get_index);
            }
            
            setTouchDeltaY(deltaY);
        }
       
    }

    const endSelectSkill = () => {


        if(selectedSkillIndex !== undefined){

            const selectedSkill = fightLog?.state.buffSkills[selectedSkillIndex];
            if(selectedSkill && touchDeltaY && touchDeltaY < -50){ //超过-50才出牌
                startManualFight(selectedSkill.uid)
            }
        }

        setSeletedSkillIndex(undefined);
    }

    const changeMT = () => {
        if(MT){
            setMT(false);
            setTimerSeconds(7);
        }else{
            setMT(true);
        }
    }


    return (
        <div id="eventPage" className={pageState}>
            {background ? <div className="background" style={{backgroundImage: `url("${background}")`}}></div> : null}
            
            <div className='dialogue_content' onClick={addStep}>
                { meetHeros.length ? <div className={`meetHeros ${meetHerosPosition}`}>
                    <div className="heros">
                        {meetHeros.map( (hero:any,index:number) => {
                                const avatar = hero.image ? `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_200,limit_0` :  `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_200,limit_0`
                                return <div className='hero' key={`meethero_${index}`}>{avatar ? <img className='avatar' src={avatar} alt="" /> : null }</div>
                            } )}
                    </div>
                </div> : null}

                <div className="content">
                    <div className={`dialogue ${dialogue.type}`}>
                        <Typewriter words={dialogue.words} speed={dialogue.typeSpeed}/>

                        {dialogue.options ? <>
                            {dialogue.options.map((option: { text: string,jumpKey : string },index:number) => {
                                const style = {
                                    animationDelay : `${dialogue.option_show_delay + index*1000}ms`
                                }

                                const jumpKey = option.jumpKey || '';
                    
                                return <label key={`option_${index}`} style={style}><input 
                                    type="radio" 
                                    name="option_radio"
                                    onChange={e => onOptionChanged(e.target.value,jumpKey)}
                                    value={option.text}
                                    checked={ checkedText === option.text}
                                    /><span>{option.text}</span></label>
                            })}
                            </>
                        : null}
                        {dialogue.avatar ? <img className='avatar' src={dialogue.avatar} alt="" /> : null }
                        {dialogue.name ? <h3 className='hero_name' >{dialogue.name}</h3> : null }
                    </div>
                </div>
            </div>

            <div className='game_content'>
                <div className='game_content_Container'>
                    
                    {/*beChallenged hero cards  */}
                    <div className='cardContent'>    
                        {fightLog?.state.beChallenged.team.map( (hero:any,index:number) => {
                            return <HeroCard hero={hero} positions={positions} index={index} effects={effects} type={'beChallenged'} key={`hero_${hero.id}`} ></HeroCard>
                        })}
                    </div> 
                    {/* challenge hero cards */}
                    <div className='cardContent'>    
                        {fightLog?.state.challenge.team.map( (hero:any,index:number) => {
                            return <HeroCard hero={hero} positions={positions} index={index} effects={effects} type={'challenged'} key={`hero_${hero.id}`} ></HeroCard>
                        })}
                    </div> 

                    {/* skills */}

                    {fightLog?.state.point && fightLog?.state.point > 0 ? 
                        <div className='cardContent' 
                        onTouchStart={e => startSeletcSkill(e)}
                        onTouchMove={e => selectSkill(e)}
                        onTouchEnd={e => endSelectSkill()}
                    >    
                        {fightLog?.state.buffSkills.map( (buffskill:any,index:number) => {
                            const equipment = buffskill.skill;
                            const image_url =  `https://oss.usecubes.cn/${equipment.category.image.url}?x-oss-process=image/resize,w_300,limit_0`;
                            const name =  equipment.category? equipment.category.cnName : '';
                            const selected = (selectedSkillIndex===index && fightLog.state.turn === 'challenge')  ? true : false;

                            let hero_image_url = ``;
                            const hero = equipment.hero;

                            const existResource = resource?.find( r => r.name === `hero_${hero.id}` );

                            if(existResource){
                                hero_image_url = existResource.url;
                            }

                            const transformZ = fightLog.state.turn === 'challenge' ? `rotateZ(${index-3}deg)` : `rotateZ(${3-index}deg)`;

                            let deltaY = selected ? ( touchDeltaY || 0) : 0; 
                            if(deltaY < -50){deltaY = -50;}else if(deltaY >0){deltaY = 0}

                            const style = {
                                left : `${positions.skills[index].left}px`,
                                top : `${positions.skills[index].top + deltaY}px`,
                                transitionDelay : `${150+index*100}ms`,
                                transform: transformZ 
                            }

                            const effect = effects.find(e => e.id === buffskill.uid );

                            const arraw_left_style = {
                                transform : selected ? `rotate( -${25- (0-deltaY)/50*25 }deg)` : ''
                            }

                            const arraw_right_style = {
                                transform : selected ? `rotate( ${25- (0-deltaY)/50*25 }deg)` : ''
                            }

                            const cl = `card equipment ${effect? (fightLog.state.turn === 'challenge' ? 'release_up' : 'release_down') : ''} ${selected ? 'selected' : ''}`;

                            return <div key={`equipment_${index}`} className={cl} style={style}>
                                <div className='skill_main'>
                                    {image_url ? <img className='bg' src={image_url} alt='' ></img> : null}
                                    {name ? <div className="name" >
                                        {name}    
                                    </div> : null}
                                    {hero_image_url ? <div className='avatar'><img src={hero_image_url} alt='' ></img></div> : null}


                                    {buffskill.attack ? <div className="attack_data">
                                        <div className="icon">
                                            <img src="/assets/icons/skill_sword.svg" alt="" />
                                        </div>
                                        <div className="value">
                                            {buffskill.attack}
                                        </div>
                                    </div>: null}
                                    
                                    {buffskill.defense ? <div className="defense_data">
                                        <div className="icon">
                                            <img src="/assets/icons/skill_shield.svg" alt="" />
                                        </div>
                                        <div className="value">
                                            {buffskill.defense}
                                        </div>
                                    </div> : null}


                                    <div className="attack_mode">
                                        <div className="icon">
                                            {equipment.category.isGroupAttack  ? <img src="/assets/icons/skill_multiple.svg" alt="" /> : <img src="/assets/icons/skill_single.svg" alt="" />}
                                        </div>
                                    </div>


                                    {buffskill.buffType === 'negative' || buffskill.buffType === 'positive' ? <div className="buff_mode">
                                        <div className="icon">
                                            {buffskill.buffType === 'negative' ? <img src="/assets/icons/skill_negative.svg" alt="" />:null}
                                            {buffskill.buffType === 'positive' ? <img src="/assets/icons/skill_positive.svg" alt="" />:null}
                                        </div>
                                    </div> : null}
                                    

                                    <div className="desc">
                                        {equipment.category.description}
                                    </div>
                                </div>
                                
                                {selected ? <div className="arraw">
                                    <div className="left" style={arraw_left_style}></div>
                                    <div className="right" style={arraw_right_style}></div>
                                </div>:null}
                                
                            </div>
                        })}
                        </div> :null}
                    


                    {fightLog?.state.point && fightLog?.state.point > 0 ? 
                    
                    <div className={`gameInfos ${fightLog?.state.turn}`}>

                    { fightLog?.state.turn === "challenge" ?  <>{!MT  ?
                    <div className="remainingRounds timerRounds">
                        <span>即将</span>
                        <b>{timerSeconds}s</b>
                        <span>出牌</span>
                    </div>    
                    : <div className="remainingRounds endRounds"
                        onClick={ (e) => {startAutoFight(true)} }
                        >
                        <div className="icon">
                            <img src="/assets/icons/close.svg" alt="" />
                        </div>
                        <div className="texts">
                            <span>结束</span>
                            <span>回合</span>
                        </div>
                    </div> } </>:null}


                    <div className="remainingRounds">
                        <span>剩余</span>
                        <b>{fightLog?.state.point}</b>
                        <span>回合</span>
                    </div>

                    {fightLog?.state.turn === "challenge" ? <div className="remainingRounds cardsMethod" 
                        onClick={ (e) => { changeMT() } }
                    >
                        {MT? <span>手动</span> : <span>自动</span>}
                        <b>换</b>
                        <span>出牌中</span>
                    </div> : null}
                </div> :null}
                    

                </div>
            
                <PhaserGame ref={phaserRef} />
               
            </div>

            {showWinResult ? <div className='winResultOuter' onClick={backtohome}>
                <div className="bg"></div>
                <div className="halo">
                    <img src="/assets/images/halo.png" alt="" />
                </div>
                <div className="resultInner">
                    <div className="resultWrap">
                        <p className="gap"></p>
                        <p className="resultText">挑战成功</p>
                        <p className="tip">点击任意位置返回</p>
                    </div>
                </div>
            </div> : null}

            {showLoseResult ? <div className='loseResultOuter' onClick={backtohome}>
                <div className="bg"></div>
                
                <div className="resultInner">
                    <div className="resultWrap">
                        <p className="gap"></p>
                        <p className="resultText">挑战<span>失</span><span>败</span></p>
                        <p className="tip">点击任意位置返回</p>
                    </div>
                </div>
                <div className="lose_hero">
                    <img src="/assets/images/lose_hero.png" alt="" />
                </div>
            </div> : null}

            {showAwardResult ? <div className='awardResultOuter'>
                <div className="bg"></div>
                <div className="resultInner">
                    <div className="resultWrap">
                        <div className="title">领取奖励</div>

                        {event?.awardGroups[0].awards.map( (award,index:number) => {
                            return <div key={`award_item_${index}`}><AwardItem award={award}></AwardItem></div>
                        })}

                        <div className="gap"></div>

                        <div className="btns">
                            <div className="accept_button" onClick={acceptAward}>收下</div>
                        </div>

                    </div>
                </div>
            </div> : null}

        </div>
    )
}

export default EventPage

