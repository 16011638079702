import { Game } from './scenes/Game';
// import { GameOver } from './scenes/GameOver';
// import { MainMenu } from './scenes/MainMenu';
import Phaser from 'phaser';
// import { Preloader } from './scenes/Preloader';

// Find out more information about the Game Config at:
// https://newdocs.phaser.io/docs/3.70.0/Phaser.Types.Core.GameConfig
const config = {
    type: 0, // 测试一下。
    width: "100%",
    height: "100%",
    parent: 'game-container',
    transparent: true,
    // zoom : 0.5,
    scene: Game
};

const StartGame = (parent: string) => {

    return new Phaser.Game({ ...config, parent });

}

export default StartGame;
