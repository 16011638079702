import { useRef, useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';


import axios from 'axios';
import User from '../../component/user'
import HeroCard from '../../component/heroCard';

import wx from 'weixin-js-sdk'

import './index.less';

import { useParams,redirect,useNavigate  } from 'react-router-dom';


interface User {
    checkin : Date;
    city : string;
    computility : number;
    computilityLimit : number;
    country: string;
    created : Date;
    headimgurl : string;
    id : number;
    name : string;
    openid : string;
    platform : string;
    province : string;
    sex : number;
    silicon : number;
    unionid : string;
}

interface Assets {
    heros: any[];
    cores: any[];
    equipments : any[];
}
  

function Userpage(props:any){

    const { userId } = useParams();

    const [userName,setUserName] = useState<string>();
    const [team,setTeam] = useState<any[]>();

    const [heroA,setHeroA] =  useState<any>();
    const [heroB,setHeroB] =  useState<any>();
    const [heroC,setHeroC] =  useState<any>();

    const [user,setUser] = useState<User>();


    const [heroPriority,setHeroPriority] = useState<number|null>(); 

    const [userCardTypes,setUserCardTypes] = useState<number>();

    const navigate = useNavigate();

     // 获取用户信息
     useEffect( ()=> {
        axios.get(
            `/api/v1/skibidi_toilet_card/getUser`
        ).then( response => {
            if(response.statusText === 'OK'){
                setUser(response.data);
            }
        } )
    },[]);



    useEffect( ()=>{
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: '', // 必填，公众号的唯一标识
            timestamp: 0, // 必填，生成签名的时间戳
            nonceStr: '', // 必填，生成签名的随机串
            signature: '',// 必填，签名
            jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
          });
    } ,[])

    // 获取用户名字
    useEffect( ()=> {

        if(userId){
            axios.get(
                `/api/v1/skibidi_toilet_card/getUserName?userId=${userId}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    setUserName(response.data);          
                }
            } )
        }
    },[]);

    // 请求战队
    useEffect( ()=> {
        if(userId){
            axios.get(
                `/api/v1/skibidi_toilet_card/heroteam?userId=${userId}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    const getTeam = response.data;
                    setTeam(getTeam);          
                }
            } )
        }
    },[]);

     // 获取用户卡牌的种类
     useEffect( ()=> {
        if(userId){
            axios.get(
                `/api/v1/skibidi_toilet_card/getusercardtypes?userId=${userId}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    setUserCardTypes(response.data)      
                }
            } )
        }
    },[]);


    useEffect(()=>{
        if(team){
            const hero_A = team.find( (e:any) => e.priority === 1 );
            const hero_B = team.find( (e:any) => e.priority === 2 );
            const hero_C = team.find( (e:any) => e.priority === 3 );
            setHeroA(hero_A);
            setHeroB(hero_B);
            setHeroC(hero_C);
        }
    },[team])



   const scanCode = () => {
        wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        }
      });
   }

//    const challengeThisUser = () => {
//         axios.post( `/api/v1/skibidi_toilet_card/matchfight`, {
//             scriptID: 14,
//             beChallengeId : userId
//         }).then( response => {
//             console.log(response);
//             if(response.statusText === 'Created' && response.data && response.data.fight){
//                 navigate(`/event/0/14/${response.data.fight.id}/${response.data.scriptLog.id}`, { replace: true }); // <-- redirect
//             }else if(response.statusText === 'Created' && response.data && response.data.message){
//                 // todo: 添加整站统一的alert。
//                 alert(response.data.message);
//             }
//         } );
//    }


    return (
        <div id="userPage" style={{
            backgroundImage : "url('/assets/images/home_bg.jpg')"
        }}>
            <header>
                <Link className="icon"  to={'/'}>
                    <img src="/assets/icons/back.svg" alt="" />
                </Link>
                {userName}
                <div className='icon'></div>
            </header>
            <div className="heroTeam">
                {userName ? <div className="title"><b>{`${userName}`}</b>的战队：</div> : null}
                <div className="teamWrap">

                    {heroA ? 
                        <div className="cardWrap" >
                            <HeroCard
                            hero={heroA}
                            cl=''
                            ></HeroCard>                                 
                        </div>
                        : 
                        <div className="placeholder" ></div>
                    }
                    {heroB ? 
                        <div className="cardWrap" >
                         <HeroCard
                         hero={heroB}
                         cl=''
                         ></HeroCard> 
                        </div>: 
                        <div className="placeholder" ></div>
                    }
                    {heroC ? 
                        <div className="cardWrap" >
                         <HeroCard
                         hero={heroC}
                         cl=''
                         ></HeroCard>
                     </div>: 
                        <div className="placeholder" ></div>
                    }
                </div>
            </div>

            <div className="cardsLinks">
                <Link to={`/cards/${userId}`}>
                    {userCardTypes ? <em>收藏数：{userCardTypes}</em> : null}
                    <img src="/assets/icons/mycards.png" alt="" />
                </Link>
            </div>


            <div className="eventLinks">

                {user?.id != userId && heroA && heroB && heroC ?  <div className="eventButtonWrap">
                    <div className="eventButtonShadow"></div>
                    <Link className="eventButton" to={`/event/6?opponent=${userId}`}>
                        <img src="/assets/icons/fight.svg" alt="" />
                        <div className="title">挑战</div>
                    </Link>
                </div>   : null}
                             


            </div>            
        </div>
    )
}

export default Userpage