import { EventBus } from '../EventBus';
import { GameObjects, Scene } from 'phaser';
// import { PhaserGame } from '../PhaserGame';

// 屏幕上skill的摆放数量
const SKILL_ONSCREEN_COUNT = 6;


export class Game extends Scene
{
    gameobjects:any =  {};



    constructor ()
    {
        super('Game');
    }

    init(){
        // const element = document.createElement('style');
        // document.head.appendChild(element);
        // const sheet = element.sheet;
        // let styles = '@font-face { font-family: "troika"; src: url("assets/fonts/ttf/troika.otf") format("opentype"); }\n';
        // sheet?.insertRule(styles, 0);
    }

    preload (){
        // 载入背景图片
        this.load.image('background', 'https://oss.usecubes.cn/skibidi/E4bgw1gsYHe54bAJ.png');
    }

    create ()
    {
        // 添加背景图片,到中央。
        // const bg = this.add.image(this.cameras.main.width / 2, this.cameras.main.height / 2, 'background');
        // const scaleX = this.cameras.main.width / bg.width;
        // const scaleY = this.cameras.main.height / bg.height;
        // const scale = Math.max(scaleX, scaleY);
        // bg.setScale(scale).setScrollFactor(0);

        // // bg.setSize()
        // bg.preFX?.addBlur();
        EventBus.emit('current-scene-ready', this);
    }




    // 渲染初始化
    renderInit (fightLog:any){
        this.renderHeros(fightLog.state.beChallenged.team,'npc',fightLog.state.turn);
        this.renderHeros(fightLog.state.challenge.team,'character' ,fightLog.state.turn);
        this.renderSkills(fightLog.state.buffSkills , fightLog.state.turn);
        this.renderStatus(fightLog.state,fightLog.state.turn);
    }

    loadImages (resource : any[]){
        resource.forEach(r => {
            this.load.image(r.name,r.url);
        })
    }

    renderStatus( state:any , turn:string ){
        const y = (turn == 'challenge') ? 340 : 20;
        const pT = this.children.list.find(x => x.name === `point` ) as GameObjects.Text;

        if(pT){
            pT.setText(`剩余回合：${state.point}`).setY(y);
        }else{
            const pointText = this.add.text(0,y,`剩余回合：${state.point}`,{
                color: '#ffffff',
                fixedWidth: this.scale.width,
                align: 'center',
                fontSize: 24,
                fixedHeight: 36,
                
                shadow : {
                    offsetX : 1,
                    offsetY : 1,
                    color : '#000',
                    blur : 0
                }
            })
            pointText.name = 'point';
        }
    }

    renderHeros (team:[any],type:string,turn:string)
    {        

        return;

        // let y = type === 'npc' ? 150 : 500;

        // if(turn === 'beChallenged' && type == 'character'){
        //     y = 650;
        // }

        // 按hp排序？
        // 不需要挪位置
        // const aliveTeam = team.filter( h => h.data.hp );
        // const deadTeam = team.filter( h => !h.data.hp );

        // const newTeam = aliveTeam.concat(deadTeam);

        // 先渲染最后一个？
        // team.forEach( (orderhero,index) => {
        //     const hero = team[team.length-1-index];

        //     const heroSpirte = this.children.list.find(x => x.name === `hero_hp_${hero.id}` ) as GameObjects.Sprite;
        //     const hp = hero.data.hp;
        //     const maxHp = hero.data.maxHp;


        //     const depth = 200 + index - (hp <= 0 ? 50 + index * 2 : 0);

        //     // 最后面的最大。
        //     const width = [140,160,180][index];
        //     const left = [this.scale.width - 10 - 140/2, (this.scale.width - 10 - 140/2 + 10+180/2)/2 ,10+180/2][index];

        //     if(!heroSpirte){
        //         // 默认尺寸是140。
        //         const h = this.add.sprite(left,y, `hero_${hero.id}`).setDisplaySize(width,width);
        //         h.name = `hero_${hero.id}`;
        //         h.setDepth(depth);

        //         if(!hp){
        //             h.setAlpha(0.6);
        //         }

        //         // TODO: 这里的阴影需要优化
        //         const fxShadow = h.preFX?.addShadow(0, 0, 0.01, 2, 0x999999, 10);

        //         // const delayTime = type === 'npc' ? 0 : 100;

        //         const hp_bg = this.add.graphics({
        //             fillStyle : {
        //                 color : 0x000000,
        //                 alpha : 0.8
        //             }
        //         });
               
        //         hp_bg.fillRect(left-width/2,y-width/2,width,18);
        //         hp_bg.fillRoundedRect(left+width/2-64,y-width/2+18,64,18,{ tl: 0, tr: 0, bl: 5, br: 5 });
        //         hp_bg.name = `hero_hp_bg_${hero.id}`;
        //         hp_bg.setDepth(depth);

        //         const progress = this.add.graphics();

        //         progress.fillStyle(0xD8D8D8);
        //         progress.fillRect(left-width/2 + 5,  y-width/2 + 6, width - 64 - 10, 6);
        //         progress.fillStyle(0xCC5252);
        //         progress.fillRect(left-width/2 + 5,  y-width/2 + 6, (width - 64 - 10) * hp/maxHp, 6);

        //         progress.name = `hero_hp_progress_${hero.id}`;
        //         progress.setDepth(depth);

    
        //         const hpText = this.add.text(left+width/2-64, y-width/2 + 4, `HP:${hero.data.hp}` ,{
        //           color : '#CC5252',
        //           align: 'center' ,
        //           fixedWidth: 64,
        //           fontSize : 12
        //         });
      
        //         hpText.name = `hero_hp_${hero.id}`;

        //         hpText.setDepth(depth);

        //         const defenseText = this.add.text(left+width/2-64, y-width/2 + 3 + 18, `DEF:${hero.data.defense}`,{
        //             color: '#44A0C7',
        //             align: 'center',
        //             fixedWidth: 64,
        //             fontSize : 12
        //         })

        //         defenseText.name =  `hero_def_${hero.id}`;
        //         defenseText.setDepth(depth);


        //         const name_bg = this.add.graphics();

        //         name_bg.fillStyle(0xF3E1B0);
        //         name_bg.lineStyle(5, 0x000000, 1.0);

        //         name_bg.fillRect(left - 40  ,  y + width/2  - 30 , 80, 20);
        //         name_bg.strokeRect(left - 40  ,  y + width/2  - 30 , 80, 20);

        //         name_bg.name =  `hero_name_bg_${hero.id}`

        //         name_bg.setDepth(depth);


        //         const nameText = this.add.text(left - 40  ,  y + width/2  - 25, `${hero.category.cnName}`,{
        //             color: '#BA410D',
        //             align: 'center',
        //             fixedWidth: 80,
        //             fixedHeight: 20,
        //             fontSize : 12
        //         });

        //         nameText.name = `hero_name_${hero.id}`;
        //         nameText.setDepth(depth);

        //     }else{
        //         const heroSpirte = this.children.list.find(x => x.name === `hero_${hero.id}` ) as GameObjects.Sprite;
        //         let playDamageAnimation: boolean = false;
        //         let hp_reduce:number = 0;
        //         let defense_reduce:number = 0;

        //         if(heroSpirte){
        //             heroSpirte.setDisplaySize(width,width);
        //             heroSpirte.setDepth(depth);

        //             if(!hp){
        //                 heroSpirte.setAlpha(0.6);
        //             }

        //             heroSpirte.setPosition(left,y);
        //         }

        //         const hp_bg = this.children.list.find(x => x.name === `hero_hp_bg_${hero.id}` ) as GameObjects.Graphics;
        //         if(hp_bg){
        //             hp_bg.clear();
        //             hp_bg.fillRect(left-width/2,y-width/2,width,18);
        //             hp_bg.fillRoundedRect(left+width/2-64,y-width/2+18,64,18,{ tl: 0, tr: 0, bl: 5, br: 5 });
        //             hp_bg.setDepth(depth);
        //         }

        //         const hpText = this.children.list.find(x => x.name === `hero_hp_${hero.id}` ) as GameObjects.Text;
        //         if(hpText){

        //             playDamageAnimation = playDamageAnimation || Number(hpText.text.replace('HP:','')) > hero.data.hp;
        //             hp_reduce = Number(hpText.text.replace('HP:','')) - hero.data.hp;
        //             hpText.setText(`HP:${hero.data.hp}`).setY(y-width/2 + 4).setX(left+width/2-64);
        //             hpText.setDepth(depth);
        //         };

        //         const hpProgress = this.children.list.find(x => x.name === `hero_hp_progress_${hero.id}` ) as GameObjects.Graphics;
        //         if(hpProgress){
        //             hpProgress.clear();
        //             hpProgress.fillStyle(0xD8D8D8);
        //             hpProgress.fillRect(left-width/2 + 5,  y-width/2 + 6, width - 64 - 10, 6);
        //             hpProgress.fillStyle(0xCC5252);
        //             hpProgress.fillRect(left-width/2 + 5,  y-width/2 + 6, (width - 64 - 10) * hp/maxHp, 6);
        //             hpProgress.setDepth(depth);
        //         };

        //         const defText = this.children.list.find(x => x.name === `hero_def_${hero.id}` ) as GameObjects.Text;
        //         if(defText){
        //             playDamageAnimation = playDamageAnimation || Number(defText.text.replace('DEF:','')) > hero.data.defense;
        //             defense_reduce = Number(defText.text.replace('DEF:','')) - hero.data.defense;
        //             defText.setText(`DEF:${hero.data.defense}`).setY( y-width/2 + 3 + 18).setX(left+width/2-64);
        //             defText.setDepth(depth);
        //         };    

        //         const origin_hero_name_bg = this.children.list.find(x => x.name === `hero_name_bg_${hero.id}` ) as GameObjects.Graphics;
        //         if(origin_hero_name_bg){
        //             origin_hero_name_bg.destroy();

        //             const name_bg = this.add.graphics();

        //             name_bg.displayOriginX = 0; // Set origin to middle point
        //             name_bg.displayOriginY = 0; // Set origin to middle point
    
        //             name_bg.fillStyle(0xF3E1B0);
        //             name_bg.lineStyle(5, 0x000000, 1.0);
    
        //             name_bg.fillRect(left - 40  ,  y + width/2  - 30 , 80, 20);
        //             name_bg.strokeRect(left - 40  ,  y + width/2  - 30 , 80, 20);
    
        //             name_bg.name =  `hero_name_bg_${hero.id}`
        //             name_bg.setDepth(depth);    
        //         }

        //         const origin_nameText = this.children.list.find(x => x.name === `hero_name_${hero.id}` ) as GameObjects.Text;
        //         if(origin_nameText){
        //             origin_nameText.destroy();

        //             const nameText = this.add.text(left - 40  ,  y + width/2  - 25, `${hero.category.cnName}`,{
        //                 color: '#BA410D',
        //                 align: 'center',
        //                 fixedWidth: 80,
        //                 fixedHeight: 20,
        //                 fontSize : 12
        //             });
    
        //             nameText.name = `hero_name_${hero.id}`;
        //             nameText.setDepth(depth);
        //         }

        //         // 播放伤害动画
        //         // TODO: 播放伤害值
        //         if( playDamageAnimation && heroSpirte){
        //             this.tweens.add({
        //                 targets: heroSpirte,
        //                 alpha : 0.5,
        //                 ease: 'sine.inout',
        //                 yoyo: true,
        //                 repeat: 3,
        //                 duration: 100
        //             });
        //         }


        //         if(hp_reduce > 0){
        //             const hp_reduce_text = this.add.text(left+width/2-100, y-width/2 + 4, `-${hp_reduce}` ,{
        //                 color : '#CC5252',
        //                 align: 'center' ,
        //                 fixedWidth: 100,
        //                 fontSize : 24,
        //                 strokeThickness : 1,
        //                 stroke : '#fff'
        //               })
        //             //   const fxShadow = hp_reduce_text.preFX?.addShadow(0, 0, 0.01, 2, 0x999999, 10);

        //               hp_reduce_text.setDepth(depth+100);
                  

        //               this.tweens.add({
        //                 targets: hp_reduce_text,
        //                 alpha : 0.1,
        //                 duration: 1000,
        //                 onUpdate: (tween) => {
        //                     // console.log('tween',tween.getValue())
        //                     const v = tween.getValue();
        //                     hp_reduce_text.setFontSize(48 - v * 24);
        //                 },
        //                 onComplete: () => {
        //                     hp_reduce_text.destroy();
        //                 }
        
        //             });

        //         }

        //         if(defense_reduce > 0){
        //             const defense_reduce_text = this.add.text(left+width/2-100, y-width/2 + 3 + 18, `-${defense_reduce}` ,{
        //                 color : '#44A0C7',
        //                 align: 'center' ,
        //                 fixedWidth: 100,
        //                 fontSize : 24,
        //                 strokeThickness : 1,
        //                 stroke : '#fff'
        //               })
        //               defense_reduce_text.setDepth(depth+100);
        //             //   const fxShadow = defense_reduce_text.preFX?.addShadow(0, 0, 0.01, 2, 0x999999, 10);

        //               this.tweens.add({
        //                 targets: defense_reduce_text,
        //                 alpha : 0,
        //                 duration: 2000,
        //                 onUpdate: (tween) => {
        //                     const v = tween.getValue();
        //                     defense_reduce_text.setFontSize(48 - v * 24);
        //                 },
        //                 onComplete: () => {
        //                     defense_reduce_text.destroy();
        //                 }
        
        //             });

        //         }



        //     }

        //     // // clear all buffs and add buffs first
        //     const buffChildren = this.children.list.filter(x => x.name.includes(`buff_${hero.id}`) );
        //     this.children.remove(buffChildren);

        //     if(hero.data.buffs.length){
        //         const buffs_bg_width = hero.data.buffs.length *( 28 + 3) + 3; 

        //         const buffs_bg = this.add.graphics({
        //             fillStyle : {
        //                 color : 0xffffff
        //             }
        //         });

        //         buffs_bg.fillRoundedRect(left+width/2 - buffs_bg_width, y - width/2- 28 - 3 - 3, buffs_bg_width , 28+3+3, { tl: 5, tr: 5, bl: 0, br: 0 });
        //         buffs_bg.name = `buff_${hero.id}`;

        //         hero.data.buffs.forEach( (buff:any,index:number) => {
        //             const buff_image = this.add.image( left+width/2 - buffs_bg_width + 14 + 3 + index * (28+3) , y - width/2- 28 - 3 - 3 + 28/2 + 3 , `equipment_${buff.skill.category.cnName}` ).setDisplaySize(28,28);
        //             buff_image.name =  `buff_${hero.id}`;
        //         })

        //     }
        // });


        // TODO: 这里也需要移出
    }


    renderSkills ( skills : [any] , turn : string){
        
        const y = turn == 'challenge' ? 700 : 330;

        const width = 100;//this.scale.width/ SKILL_ONSCREEN_COUNT;
        const avatar_width = 30;
        const gap =( this.scale.width - 10 ) / (SKILL_ONSCREEN_COUNT + 1);

        // TODO: 添加头像

        skills.forEach( (skill,index) => {
            // 1. 以前有的话，挪位置。
            // 2. 以前没有的话，创建。
            // 3. 现在没有的话，消失掉他。
            const depth = 100-index;
            const origin_skill_text = this.children.list.find(x => x.name === `skill_text_${skill.uid}` ) as GameObjects.Text;

            const skill_x_coord = gap*index + width/2 + 10;
            // const text_x_coord = (width-5)*index;
            const skill_hero_x_coord = skill_x_coord + width/2 - avatar_width/2;
            const skill_hero_y_coord = y - width/2 - avatar_width/2;

            if(!origin_skill_text){
                const imageName = (skill.skill && skill.skill.category.cnName) ?  `equipment_${skill.skill.category.cnName}` : '';

                if(imageName){
                    const sprite = this.add.image(skill_x_coord,y,imageName).setDisplaySize(width,width);
                    sprite.name = `skill_sprite_${skill.uid}`;
                    const fxShadow = sprite.preFX?.addShadow(0, 0, 0.01, 2, 0x999999, 10);
                    sprite.setDepth(depth);
                }

                const heroAvatarName = (skill.skill && skill.skill.hero) ?  `hero_${skill.skill.hero.id}` : '';
                if(heroAvatarName){
                    const hero_avatar_sprite = this.add.image(skill_hero_x_coord,skill_hero_y_coord,heroAvatarName).setDisplaySize(avatar_width,avatar_width);
                    hero_avatar_sprite.name = `skill_hero_sprite_${skill.uid}`;
                    hero_avatar_sprite.setDepth(depth);
                }

                const value_bg = this.add.graphics({
                                    fillStyle : {
                                        color : 0x000000,
                                        alpha : 0.8
                                    }
                                });
               
                value_bg.fillRect(skill_x_coord+width/2-50,y-width/2,50,30);
                value_bg.setDepth(depth);

                value_bg.name = `skill_value_bg_${skill.uid}`;

                if(skill.attack){
                    const dmgText = this.add.text(skill_x_coord+width/2-50, y-width/2 + 2 , `DMG:${skill.attack}` ,{
                        color : '#CC5252',
                        align: 'center' ,
                        fixedWidth: 50,
                        fontSize : 11,
                        fixedHeight: 15
                      });   
                    dmgText.setDepth(depth);
                    dmgText.name = `skill_dmgText_${skill.uid}`;
                }

                if(skill.defense){
                    const defenseText = this.add.text(skill_x_coord+width/2-50, y-width/2 + 15 , `DEF:+${skill.defense}` ,{
                        color : '#44A0C7',
                        align: 'center' ,
                        fixedWidth: 50,
                        fontSize : 11,
                        fixedHeight: 15
                      });   
                      defenseText.setDepth(depth);
                      defenseText.name = `skill_defenseText_${skill.uid}`;
                }

                const line = this.add.graphics();
                line.lineStyle(1, 0xffffff, 1);
                line.beginPath();
                line.moveTo(skill_x_coord + width/2, y + width/2);

                const circle = new Phaser.Geom.Circle(skill_x_coord + width/2, y + width/2, width*1.4);
                const p = Phaser.Geom.Circle.CircumferencePoint(circle, Phaser.Math.DegToRad(135));
                line.lineTo(p.x,p.y);
                line.strokePath();

                line.name = `skill_line_${skill.uid}`;


                const text = this.add.text(skill_x_coord - width/2,y + width *1.3,skill.skill.category.cnName, { fontSize: 12, color: '#ffffff' , align: 'center' ,fixedWidth : width });
                text.name = `skill_text_${skill.uid}`;

                text.rotation = -Math.PI/4;
            }else{
                const origin_skill_sprite = this.children.list.find(x => x.name === `skill_sprite_${skill.uid}` ) as GameObjects.Sprite;
                const origin_skill_hero_sprite = this.children.list.find(x => x.name === `skill_hero_sprite_${skill.uid}` ) as GameObjects.Sprite;

                const orign_value_bg = this.children.list.find(x => x.name === `skill_value_bg_${skill.uid}` ) as GameObjects.Graphics;
                const origin_dmgText = this.children.list.find(x => x.name === `skill_dmgText_${skill.uid}` ) as GameObjects.Text;
                const origin_defenseText = this.children.list.find(x => x.name === `skill_defenseText_${skill.uid}` ) as GameObjects.Text;
                const origin_line = this.children.list.find(x => x.name === `skill_line_${skill.uid}` ) as GameObjects.Graphics;

                if(origin_skill_sprite){
                    this.tweens.add({
                        targets: origin_skill_sprite,
                        ease: 'Sine.easeInOut',
                        x : skill_x_coord,
                        duration: 300
                    });    
                }

                if(origin_skill_hero_sprite){
                    this.tweens.add({
                        targets: origin_skill_hero_sprite,
                        ease: 'Sine.easeInOut',
                        x : skill_hero_x_coord,
                        duration: 300
                    });   

                }

                if(orign_value_bg){
                    orign_value_bg.destroy();
                    const value_bg = this.add.graphics({
                        fillStyle : {
                            color : 0x000000,
                            alpha : 0.8
                        }
                    });
                    value_bg.fillRect(skill_x_coord+width/2-50,y-width/2,50,30);
                    value_bg.setDepth(depth);
                    value_bg.name = `skill_value_bg_${skill.uid}`;
                }

                if(origin_dmgText){
                    origin_dmgText.destroy();
                    const dmgText = this.add.text(skill_x_coord+width/2-50, y-width/2 + 2 , `DMG:${skill.attack}` ,{
                        color : '#CC5252',
                        align: 'center' ,
                        fixedWidth: 50,
                        fontSize : 11,
                        fixedHeight: 15
                      });   
                    dmgText.setDepth(depth);
                    dmgText.name = `skill_dmgText_${skill.uid}`;
                }

                if(origin_defenseText){
                    origin_defenseText.destroy();
                    const defenseText = this.add.text(skill_x_coord+width/2-50, y-width/2 + 15 , `DEF:+${skill.defense}` ,{
                        color : '#44A0C7',
                        align: 'center' ,
                        fixedWidth: 50,
                        fontSize : 11,
                        fixedHeight: 15
                      });   
                      defenseText.setDepth(depth);
                      defenseText.name = `skill_defenseText_${skill.uid}`;
                }



                if(origin_line){
                    origin_line.destroy();

                    const line = this.add.graphics();
                    line.lineStyle(1, 0xffffff, 1);
                    line.beginPath();
                    line.moveTo(skill_x_coord + width/2, y + width/2);
    
                    const circle = new Phaser.Geom.Circle(skill_x_coord + width/2, y + width/2, width*1.4);
                    const p = Phaser.Geom.Circle.CircumferencePoint(circle, Phaser.Math.DegToRad(135));
                    line.lineTo(p.x,p.y);
                    line.strokePath();
    
                    line.name = `skill_line_${skill.uid}`;
                }

                // origin_skill_text.setSize(width,32);
                this.tweens.add({
                    targets: origin_skill_text,
                    ease: 'Sine.easeInOut',
                    x : skill_x_coord - width/2,
                    duration: 300
                });


            }
        })

        const skillTexts = this.children.list.filter( x => x.name.indexOf('skill_text_') !== -1 );

        // 出牌。
        // TODO：直接从返回的街口中获取。
        skillTexts.forEach( (skillText) => {
            const uid = skillText.name.replace('skill_text_','');

            const skill_sprite = this.children.list.find(x => x.name === `skill_sprite_${uid}` ) as GameObjects.Sprite;
            const origin_skill_hero_sprite = this.children.list.find(x => x.name === `skill_hero_sprite_${uid}` ) as GameObjects.Sprite;
            const skill_text = this.children.list.find(x => x.name === `skill_text_${uid}` ) as GameObjects.Text;

            const origin_line = this.children.list.find(x => x.name === `skill_line_${uid}` ) as GameObjects.Graphics;

            const orign_value_bg = this.children.list.find(x => x.name === `skill_value_bg_${uid}` ) as GameObjects.Graphics;
            const origin_dmgText = this.children.list.find(x => x.name === `skill_dmgText_${uid}` ) as GameObjects.Text;
            const origin_defenseText = this.children.list.find(x => x.name === `skill_defenseText_${uid}` ) as GameObjects.Text;

        
            if (skills.some((e:any) => e.uid === uid)) {

            }else{

                if(skill_sprite){
                    this.tweens.add({
                        targets: skill_sprite,
                        ease: 'Sine.easeInOut',
                        alpha: 0,
                        scale: 3,
                        duration: 500
                    });
                }
                if(skill_text){
                    this.tweens.add({
                        targets: skill_text,
                        ease: 'Sine.easeInOut',
                        alpha: 0,
                        scale: 3,
                        duration: 500
                    });
                }
                if(origin_skill_hero_sprite){
                    this.tweens.add({
                        targets: origin_skill_hero_sprite,
                        ease: 'Sine.easeInOut',
                        alpha: 0,
                        scale: 3,
                        duration: 500
                    });
                }
                if(origin_line){
                    origin_line.destroy();
                }

                if(orign_value_bg){
                    orign_value_bg.destroy();
                }

                if(origin_dmgText){
                    origin_dmgText.destroy();
                }

                if(origin_defenseText){
                    origin_defenseText.destroy();
                }



            }
        } )
    }


    changeScene ()
    {
        this.scene.start('GameOver');
    }
}

