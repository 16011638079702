import { useRef, useState ,useEffect} from 'react';
import axios from 'axios';

import './index.less';

import { Link } from 'react-router-dom';
import wx from 'weixin-js-sdk';

import { useParams,redirect,useNavigate  } from 'react-router-dom';


function CardsPage()
{

    const { userId } = useParams();

    // const [assets,setAssets] = useState<Assets>();
    const [heros,setHeros] = useState<any[]>();
    const [cores,setCores] = useState<any[]>();
    const [equipments,setEquipments] = useState<any[]>();

    
    const [scalemode,setScaleMode] = useState<boolean>(false);
    const [selectedCards,setSelectedCards] = useState<string[]>();
    const [selectedCardsIndex,setSelectedCardsIndex] = useState<number>(0);

    const [userName,setUserName] = useState<string>();


    useEffect( ()=>{
        //  获取用户的资产
       axios.get(
        `/api/v1/skibidi_toilet_card/userheros?userId=${userId}`,{
            responseEncoding: 'utf8mb4',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        }
        ).then( response => {
            if(response.statusText === 'OK'){
                // 拆分成包含category以及不包含category的heros
                const containCategoryHeros = response.data.heros.filter( (e:any) => e.category ).sort((a:any,b:any)=> Number(b.percentage) - Number(a.percentage)).sort((a:any,b:any)=> Number(a.category.no) - Number(b.category.no));
                const withoutCategoryHeros = response.data.heros.filter( (e:any) => !e.category );

                setHeros(containCategoryHeros.concat(withoutCategoryHeros));
            }
        } )

        axios.get(
            `/api/v1/skibidi_toilet_card/usercores?userId=${userId}`,{
                responseEncoding: 'utf8mb4',
                responseType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            ).then( response => {
                if(response.statusText === 'OK'){
                    setCores(response.data.cores.filter((e:any) => !e.hero).sort((a:any,b:any)=> Number(b.percentage) - Number(a.percentage)).sort((a:any,b:any)=> Number(a.category.no) - Number(b.category.no)));
                }
        } )

        axios.get(
            `/api/v1/skibidi_toilet_card/userequipments?userId=${userId}`,{
                responseEncoding: 'utf8mb4',
                responseType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            ).then( response => {
                if(response.statusText === 'OK'){
                    setEquipments(response.data.equipments.filter((e:any) => !e.hero).sort((a:any,b:any)=> Number(b.percentage) - Number(a.percentage)).sort((a:any,b:any)=> Number(a.category.no) - Number(b.category.no)));
                }
        } )
    },[])


    const scaleCard = (id:number,e:any,t:string,categoryid?:number) => {

        const newSelectCards = []; 

        if(t === "equipment"){
            if(categoryid){
                const sameCategorys = equipments?.filter(e => e.category.id === categoryid);
                sameCategorys && sameCategorys.map((a:any) => {
                    newSelectCards.push(`${t}_${a.id}`)
                })
            }else{
                newSelectCards.push(`${t}_${id}`)
            }
        }else if(t === "core"){
            if(categoryid){
                const sameCategorys = cores?.filter(e => e.category.id === categoryid);
                sameCategorys && sameCategorys.map((a:any) => {
                    newSelectCards.push(`${t}_${a.id}`)
                })
            }else{
                newSelectCards.push(`${t}_${id}`)
            }
        }else if(t === "hero"){
            if(categoryid){
                const sameCategorys = heros?.filter(e => {return e.category && e.category.id === categoryid});
                sameCategorys && sameCategorys.map((a:any) => {
                    newSelectCards.push(`${t}_${a.id}`)
                })
            }else{
                newSelectCards.push(`${t}_${id}`)
            }
        }

        setSelectedCards(newSelectCards);
        setScaleMode(true);
    }

    const quitSeletedMode = () => {
        setSelectedCards([]);
        setScaleMode(false);
        setSelectedCardsIndex( 0 )
    }

    const scanCode = () => {
        wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        }
      });
   }


   const changeSelectedCardsIndex = (step:number) => {
        const newIndex = selectedCardsIndex + step;
        if(selectedCards && selectedCards.length){
            if(newIndex > selectedCards.length -1){
                setSelectedCardsIndex(selectedCards.length -1 )
            }else if(newIndex < 0){
                setSelectedCardsIndex( 0 )
            }else{
                setSelectedCardsIndex( newIndex )
            }
        }
   }

    // 获取用户名字
    useEffect( ()=> {

        if(userId){
            axios.get(
                `/api/v1/skibidi_toilet_card/getUserName?userId=${userId}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    setUserName(response.data);          
                }
            } )
        }
    },[]);

    return (
        <div id="cardspage">
            <header>
                <Link className="icon"  to={'/'}>
                    <img src="/assets/icons/back.svg" alt="" />
                </Link>
                卡册
                <div className="scan" >
                    {/* <img src="/assets/icons/scan.svg" alt="" /> */}
                </div>
            </header>

            {scalemode ? <div className="bg-animation" onClick={quitSeletedMode}>
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <div id='stars4'></div>
            </div> : null}

        
            <div className="heroContent">
                {userName ? <h3><span>{userName}的英雄卡</span> </h3>: null}
                {heros && heros.length ? <div className='cardContent'>    
                    {heros.map( (hero:any,index:number) => {
                        let image_url = '';
                        if(hero.image){
                            image_url = `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_300,limit_0`;
                        }else if(hero.category && hero.category.image){
                            image_url = `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_300,limit_0`;
                        }else{
                            image_url = '';
                        }

                        let name = '';
                        if(hero.cnName){
                            name = hero.cnName;
                        }else if(hero.category && hero.category.cnName){
                            name = hero.category.cnName;
                        }else{
                            name = '';
                        }

                        const rate = (hero.category && hero.category.rate) ? hero.category.rate : '';
                        const no = (hero.category && hero.category.no) ? hero.category.no : '';


                        let isHide:boolean = false;
                        let isFold:boolean = false;

                        if(heros[index-1] &&  heros[index-1].category && hero.category && heros[index-1].category.id === hero.category.id){
                            isHide = true;
                        }else if(heros[index+1] &&  heros[index+1].category && hero.category && heros[index+1].category.id === hero.category.id){
                            isFold = true;
                        }

                        const selected = selectedCards?.find(e => e === `hero_${hero.id}`);
                        const cl = `card hero ${ selected ? 'selected' : `${isHide?'hide':''} ${isFold?'fold':''}`}`       
                        const waveStyle = {animationDelay : `${index *500}ms`}
                        let selectStyle = {};

                        if(selected){
                            const selectedIndex = selectedCards?.findIndex(e => e === `hero_${hero.id}`) || 0;
                            const positionIndex = selectedIndex - selectedCardsIndex;
                            selectStyle = {
                                transform : `translate(${positionIndex *100}%,${Math.abs(positionIndex)*10}%) rotate(${positionIndex*15}deg)`,
                                zIndex : 200 - Math.abs(positionIndex)
                            }
                        }
                        
                        
                        return <div key={`hero_${index}`} className={cl} 
                            onClick={(e) => scaleCard(hero.id, e,'hero', hero.category ? hero.category.id : null)}
                            style={selectStyle}
                            >
                            {image_url ? <img src={image_url} alt='' loading='lazy'></img> : null}
                            {name ? <div className="name" >
                                <p>{name}</p>
                                <p>{rate}&nbsp;&nbsp;{`no.${no}`}</p>    
                            </div> : null}
                            <div className="datas">
                                {/* hp */}
                                <div className="data_block">
                                    <div className="data_block_value">{hero.hp_default}</div>
                                    <div className="data_block_plus">{`+${hero.hp - hero.hp_default}`}</div>
                                    <div className="data_block_name">HP</div>
                                </div>
                                {/* attack */}
                                <div className="data_block">
                                    <div className="data_block_value">{hero.attack_default}</div>
                                    <div className="data_block_plus">{`+${hero.attack - hero.attack_default}`}</div>
                                    <div className="data_block_name">攻击</div>
                                </div>
                                {/* defense */}
                                <div className="data_block">
                                    <div className="data_block_value">{hero.defense_default}</div>
                                    <div className="data_block_plus">{`+${hero.defense - hero.defense_default}`}</div>
                                    <div className="data_block_name">防御</div>
                                </div>
                                {/* speed */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{hero.speed_default}</div>
                                    <div className="data_block_plus">{`+${hero.speed - hero.speed_default}`}</div>
                                    <div className="data_block_name">速度</div>
                                </div>

                                {/* critrate */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{`${(hero.critrate_default*100).toFixed(0)}%`}</div>
                                    <div className="data_block_plus">{`+${((hero.critrate - hero.critrate_default)*100).toFixed(0)}%`}</div>
                                    <div className="data_block_name">暴击</div>
                                </div>

                                {/* hitrate */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{`${(hero.hitrate_default*100).toFixed(0)}%`}</div>
                                    <div className="data_block_plus">{`+${((hero.hitrate - hero.hitrate_default)*100).toFixed(0)}%`}</div>
                                    <div className="data_block_name">命中</div>
                                </div>
                            </div>
                        </div>
                    })}
                    </div> : <>
                    <p  className='empty'>暂时没有可用的英雄卡。</p>
                    </> }
            </div>

            <div className="coreContent">
                {userName ? <h3>{userName}的核心卡</h3> : null}
                {cores && cores.length ? <div className='cardContent'>    
                    {cores.map( (core:any,index:number) => {

                        const image_url =  `https://oss.usecubes.cn/${core.category.image.url}?x-oss-process=image/resize,w_300,limit_0`;
                        const name =  core.category.cnName;

                        const rate =  core.category.rate;
                        const no = core.category.no;

                        let isHide:boolean = false;
                        let isFold:boolean = false;

                        if(cores[index-1] && cores[index-1].category.id === core.category.id){
                            isHide = true;
                        }else if(cores[index+1] && cores[index+1].category.id === core.category.id){
                            isFold = true;
                        }

                        const selected = selectedCards?.find(e => e === `core_${core.id}`);
                    
                        const cl = `card core ${ selected ? 'selected' : `${isHide?'hide':''} ${isFold?'fold':''}`}`       
                        const waveStyle = {animationDelay : `${index *500}ms`}
                        let selectStyle = {};

                        if(selected){
                            const selectedIndex = selectedCards?.findIndex(e => e === `core_${core.id}`) || 0;
                            const positionIndex = selectedIndex - selectedCardsIndex;
                            selectStyle = {
                                transform : `translate(${positionIndex *100}%,${Math.abs(positionIndex)*10}%) rotate(${positionIndex*15}deg)`,
                                zIndex : 200 - Math.abs(positionIndex)
                            }
                        }


                        
                        return <div key={`core_${index}`}  className={cl} 
                            onClick={(e) => scaleCard(core.id, e,'core',core.category.id)}
                            style={selectStyle}
                        >
                            {image_url ? <img src={image_url} alt='' loading='lazy'></img> : null}
                            {name ? <div className="name" >
                                <p>{name}</p>
                                <p>{rate}&nbsp;&nbsp;{`no.${no}`}</p> 
                            </div> : null}

                            {/* 只有全卡显示数据 */}
                            {core.percentage === 1 ? <div className="datas">
                                {/* hp */}
                                <div className="data_block">
                                    <div className="data_block_value">{core.category.hp_base}</div>
                                    <div className="data_block_plus">{`+${core.hp_increment}`}</div>
                                    <div className="data_block_name">HP</div>
                                </div>
                                {/* attack */}
                                <div className="data_block">
                                    <div className="data_block_value">{core.category.attack_base}</div>
                                    <div className="data_block_plus">{`+${core.attack_increment}`}</div>
                                    <div className="data_block_name">攻击</div>
                                </div>
                                {/* defense */}
                                <div className="data_block">
                                    <div className="data_block_value">{core.category.defense_base}</div>
                                    <div className="data_block_plus">{`+${core.defense_increment}`}</div>
                                    <div className="data_block_name">防御</div>
                                </div>

                                  {/* speed */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{core.category.speed_base}</div>
                                    <div className="data_block_plus">{`+${core.speed_increment}`}</div>
                                    <div className="data_block_name">速度</div>
                                </div>

                                {/* critrate */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{`${(core.category.critrate_base*100).toFixed(0)}%`}</div>
                                    <div className="data_block_plus">{`+${(core.critrate_increment*100).toFixed(0)}%`}</div>
                                    <div className="data_block_name">暴击</div>
                                </div>

                                {/* hitrate */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{`${(core.category.hitrate_base*100).toFixed(0)}%`}</div>
                                    <div className="data_block_plus">{`+${(core.hitrate_increment*100).toFixed(0)}%`}</div>
                                    <div className="data_block_name">命中</div>
                                </div>

                            </div> : <div className="pecentageWrap">
                                <div className="pecentage">{core.percentage}</div>
                                <div className="wave wave2"> 
                                    <img src="/assets/images/wave2.png" alt="" />
                                </div>
                                <div className="wave wave1">
                                    <img src="/assets/images/wave1.png" alt="" />
                                </div>
                            </div>}
                        </div>
                    })}
                    </div> : <>
                        <p className='empty'>暂时没有可用的核心卡。</p>
                    </> }
            </div>

            <div className="equipmentContent">
                {userName ? <h3>{userName}的装备卡</h3> :null}
                
                {equipments && equipments.length ? <div className='cardContent'>    
                    {equipments.map( (equipment:any,index:number) => {
                        const image_url =  `https://oss.usecubes.cn/${equipment.category.image.url}?x-oss-process=image/resize,w_300,limit_0`;
                        const name =  equipment.category.cnName;
                        const rate =  equipment.category.rate;
                        const no = equipment.category.no;


                        let isHide:boolean = false;
                        let isFold:boolean = false;

                        if(equipments[index-1] && equipments[index-1].category.id === equipment.category.id){
                            isHide = true;
                        }else if(equipments[index+1] && equipments[index+1].category.id === equipment.category.id){
                            isFold = true;
                        }

                        const selected = selectedCards?.find(e => e === `equipment_${equipment.id}`);
                    
                        const cl = `card equipment ${ selected ? 'selected' : `${isHide?'hide':''} ${isFold?'fold':''}`}`       
                        const waveStyle = {animationDelay : `${index *500}ms`}
                        let selectStyle = {};

                        if(selected){
                            const selectedIndex = selectedCards?.findIndex(e => e === `equipment_${equipment.id}`) || 0;
                            const positionIndex = selectedIndex - selectedCardsIndex;
                            selectStyle = {
                                transform : `translate(${positionIndex *100}%,${Math.abs(positionIndex)*10}%) rotate(${positionIndex*15}deg)`,
                                zIndex : 200 - Math.abs(positionIndex)
                            }
                        }
                        
                        return <div key={`equipment_${index}`} className={cl} 
                            onClick={(e) => scaleCard(equipment.id, e,'equipment',equipment.category.id)}
                            style={selectStyle}
                            >
                            {image_url ? <img src={image_url} alt=''></img> : null}

                            {equipment.percentage === 1 ? <div className="datas">
                                {/* hp */}
                                <div className="data_block">
                                    <div className="data_block_value">{equipment.category.hp_increment_base}</div>
                                    <div className="data_block_plus">{`+${equipment.hp_increment}`}</div>
                                    <div className="data_block_name">HP</div>
                                </div>
                                {/* attack */}
                                <div className="data_block">
                                    <div className="data_block_value">{equipment.category.attack_increment_base}</div>
                                    <div className="data_block_plus">{`+${equipment.attack_increment}`}</div>
                                    <div className="data_block_name">攻击</div>
                                </div>
                                {/* defense */}
                                <div className="data_block">
                                    <div className="data_block_value">{equipment.category.defense_increment_base}</div>
                                    <div className="data_block_plus">{`+${equipment.defense_increment}`}</div>
                                    <div className="data_block_name">防御</div>
                                </div>

                                {/* speed */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{equipment.category.speed_increment_base}</div>
                                    <div className="data_block_plus">{`+${equipment.speed_increment}`}</div>
                                    <div className="data_block_name">速度</div>
                                </div>

                                {/* critrate */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{`${(equipment.category.critrate_increment_base*100).toFixed(0)}%`}</div>
                                    <div className="data_block_plus">{`+${(equipment.critrate_increment*100).toFixed(0)}%`}</div>
                                    <div className="data_block_name">暴击</div>
                                </div>

                                {/* hitrate */}
                                <div className="data_block default_hide_block">
                                    <div className="data_block_value">{`${(equipment.category.hitrate_increment_base*100).toFixed(0)}%`}</div>
                                    <div className="data_block_plus">{`+${(equipment.hitrate_increment*100).toFixed(0)}%`}</div>
                                    <div className="data_block_name">命中</div>
                                </div>


                            </div>:<div className="pecentageWrap">
                                
                                <div className="pecentage">{equipment.percentage}</div>
                                <div className="wave wave2" style={waveStyle}> 
                                    <img src="/assets/images/wave2.png" alt="" />
                                </div>

                                <div className="wave wave1" style={waveStyle}>
                                    <img src="/assets/images/wave1.png" alt="" />
                                </div>
                                
                            </div>}
                            {name ? <div className="name" >
                                <p>{name}</p>
                                <p>{rate}&nbsp;&nbsp;{`no.${no}`}</p> 
                            </div> : null}
                        </div>
                    })}
                    </div> : <>
                    <p  className='empty'>暂时没有可用的装备卡。</p>
                </> }

            </div>


            {scalemode && selectedCards && selectedCards.length > 1 ? <>
                <div className="cards_back" onClick={ ()=> changeSelectedCardsIndex(-1) } >
                    <img src="/assets/icons/cards_back.svg" alt="" />
                </div>
                <div className="cards_forward" onClick={ ()=> changeSelectedCardsIndex(1) }>
                    <img src="/assets/icons/cards_forward.svg" alt="" />
                </div>
            </>:null}
        </div>
    )
}

export default CardsPage