import { useRef, useState ,useEffect} from 'react';
import { Link } from 'react-router-dom';


import axios from 'axios';
import User from '../../component/user'
import HeroCard from '../../component/heroCard';

import wx from 'weixin-js-sdk'

import './index.less';

import { useParams,redirect,useNavigate  } from 'react-router-dom';


interface User {
    checkin : Date;
    city : string;
    computility : number;
    computilityLimit : number;
    country: string;
    created : Date;
    headimgurl : string;
    id : number;
    name : string;
    openid : string;
    platform : string;
    province : string;
    sex : number;
    silicon : number;
    unionid : string;
}

interface Assets {
    heros: any[];
    cores: any[];
    equipments : any[];
}
  

function Homepage(props:any){
    const [user,setUser] = useState<User>();
    const [team,setTeam] = useState<any[]>();

    const [heroA,setHeroA] =  useState<any>();
    const [heroB,setHeroB] =  useState<any>();
    const [heroC,setHeroC] =  useState<any>();

    const [isShowSelectHero,setIsShowSelectHero] = useState<boolean>(false);

    const [assets,setAssets] = useState<Assets>();

    const [heroPriority,setHeroPriority] = useState<number|null>(); 

    const [userCardTypes,setUserCardTypes] = useState<number>();

    const navigate = useNavigate();


    useEffect( ()=>{
        wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: '', // 必填，公众号的唯一标识
            timestamp: 0, // 必填，生成签名的时间戳
            nonceStr: '', // 必填，生成签名的随机串
            signature: '',// 必填，签名
            jsApiList: ['scanQRCode'] // 必填，需要使用的JS接口列表
          });

    } ,[])

    // 签到
    useEffect( ()=> {
        axios.get(
            `/api/v1/skibidi_toilet_card/checkin`
        ).then( response => {
            if(response.statusText === 'OK'){
                setUser(response.data);
            }
        } )
    },[]);

    // 请求战队
    useEffect( ()=> {
        if(user){
            axios.get(
                `/api/v1/skibidi_toilet_card/heroteam?userId=${user.id}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    const getTeam = response.data;
                    setTeam(getTeam);          
                }
            } )
        }
    },[user]);

     // 获取用户卡牌的种类
     useEffect( ()=> {
        if(user){
            axios.get(
                `/api/v1/skibidi_toilet_card/getusercardtypes?userId=${user.id}`
            ).then( response => {
                if(response.statusText === 'OK'){
                    setUserCardTypes(response.data)      
                }
            } )
        }
    },[user]);


    useEffect(()=>{
        if(team){
            const hero_A = team.find( (e:any) => e.priority === 1 );
            const hero_B = team.find( (e:any) => e.priority === 2 );
            const hero_C = team.find( (e:any) => e.priority === 3 );
            setHeroA(hero_A);
            setHeroB(hero_B);
            setHeroC(hero_C);
        }
    },[team])

    // 获取资源
    useEffect( ()=> {
        if(user){
            axios.get(
            `/api/v1/skibidi_toilet_card/userheros?userId=${user.id}`,{
                responseEncoding: 'utf8mb4',
                responseType: 'json',
                headers: {
                    'Content-Type': 'application/json'
                }
            }
            ).then( response => {
                if(response.statusText === 'OK'){
                    setAssets(response.data);
                }
            } )
        }
    },[user]);

   const  logout = (e:any) => {
        axios.get(
            `/api/v1/skibidi_toilet_card/logout`
        ).then( response => {
            if(response.statusText === 'OK'){
                navigate(`/login`, { replace: true }); // <-- redirect
            }
        } )
   }

    //显示英雄面板
   const showHeroSelect = (priority:number) => {
        setIsShowSelectHero(true);
        setHeroPriority(priority);
   }

   const hideHeroSelect = () => {
        setIsShowSelectHero(false);
        setHeroPriority(null);
   }

   const setHeroTeam = (e:any,heroid:number) => {

        axios.put(`/api/v1/skibidi_toilet_card/heroteam`,{
            heroid ,
            priority:heroPriority
        }).then( response => {
            if(response.statusText === "OK"){
                hideHeroSelect();
                setTeam(response.data);
            }
        })

   }

   const scanCode = () => {
        wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        }
      });
   }

   const showComputilityRule = () => {
        alert(`每日登录后，自动充满至上限\n集齐的卡牌种类越多，上限越高：\n默认：40算力\n64种：80算力\n96种：120算力`)
   }


    return (
        <div id="homePage" style={{
            backgroundImage : "url('/assets/images/home_bg.jpg')"
        }}>
            <div className="logout" onClick={ (e) => logout( e)}>
                <div className="logout_button">
                    退出登录
                </div>
            </div>

            <div className="userInfos">
                {/* 暂时隐藏 */}
                {/* <div className="scan" onClick={scanCode}>
                    <img src="/assets/icons/scan.svg" alt="" />
                </div> */}

                <div className="computility" onClick={ () => showComputilityRule()}>
                    <span className="name">算力<sup><img src="/assets/icons/info.svg" alt="" /></sup></span>
                    <span className="value">{user?.computility ? user?.computility : '0' }</span>
                </div>
                <div className="gap"></div>
                <div className="silicon">
                    <span className="name">硅晶</span>
                    <span className="value">{user?.silicon ? user?.silicon : '0' }</span>
                </div>

                <div className="eventButtonWrap">
                    <div className="eventButtonShadow"></div>
                    <Link className="eventButton" to={'/event/4'}>
                        <img src="/assets/icons/gears.svg" alt="" />
                        <div className="title">制造</div>
                    </Link>
                    <div className="tag">限定卡池</div>
                </div>
            </div>

            <div className="heroTeam">
                <div className="title"><b>{`${user?.name}`}</b>的战队：</div>
                <div className="teamWrap">

                    {heroA ? 
                        <div className="cardWrap" 
                            onClick={ () => showHeroSelect( 1 )}>
                            <HeroCard
                            hero={heroA}
                            cl=''
                            ></HeroCard> 
                            <div className="icon">
                                <img src="/assets/icons/change.svg" alt="" />
                            </div>
                            <div className="change_tip"><span>更换</span></div>
                                
                        </div>
                        : 
                        <div className="placeholder" onClick={ () => showHeroSelect( 1 )}>+</div>
                    }
                    {heroB ? 
                        <div className="cardWrap" 
                        onClick={ () => showHeroSelect( 2 )}>
                         <HeroCard
                         hero={heroB}
                         cl=''
                         ></HeroCard> 
                        <div className="icon">
                            <img src="/assets/icons/change.svg" alt="" />
                        </div>
                        <div className="change_tip"><span>更换</span></div> 
                        </div>: 
                        <div className="placeholder" onClick={ () => showHeroSelect( 2 )}>+</div>
                    }
                    {heroC ? 
                        <div className="cardWrap" 
                        onClick={ () => showHeroSelect( 3 )}>
                         <HeroCard
                         hero={heroC}
                         cl=''
                         ></HeroCard> <div className="icon">
                         <img src="/assets/icons/change.svg" alt="" />
                     </div>
                     <div className="change_tip"><span>更换</span></div> 
                     </div>: 
                        <div className="placeholder" onClick={ () => showHeroSelect( 3 )}>+</div>
                    }
                </div>
            </div>

            <div className="cardsLinks">
                <Link to={`/cards/${user?.id}`}>
                    {userCardTypes ? <em>收藏数：{userCardTypes}</em> : null}
                    <img src="/assets/icons/mycards.png" alt="" />
                </Link>
                <Link to={"/compose"}>
                    <img src="/assets/icons/compose.png" alt="" />
                </Link>
            </div>


            <div className="eventLinks">
                <div className="eventButtonWrap">
                    <div className="eventButtonShadow"></div>
                    {/* <div className="flag">擂台记录</div> */}
                    <Link className="eventButton" to={'/event/3'}>
                        <img src="/assets/icons/world.svg" alt="" />
                        <div className="title">世界擂台赛</div>
                    </Link>
                </div>

                <div className="eventButtonWrap">
                    <div className="eventButtonShadow"></div>
                    <Link className="eventButton" to={'/event/2'}>
                        <img src="/assets/icons/exclamation.svg" alt="" />
                        <div className="title">突发事件</div>
                    </Link>
                    <div className="tag">限时OR卡</div>
                </div>

                <div className="eventButtonWrap">
                    <div className="eventButtonShadow"></div>
                    <Link className="eventButton" to={'/event/1'}>
                        <img src="/assets/icons/universe.svg" alt="" />
                        <div className="title">多元宇宙事件</div>
                    </Link>
                </div>
            </div>

            <div className="breakLinks">
                 <Link className='breakLinkBtn' to={'/event/5'}>
                    <div className="icon">
                        <img src="/assets/icons/gift.svg" alt="" />          
                        <div className="tag">限时</div>
                    </div>
                    <div className="text">初级芯片人礼包</div>
                </Link> 

                <Link className='breakLinkBtn' to={'/lastestheros'}>
                    <div className="icon"><img src="/assets/icons/broadcast.svg" alt="" /></div>
                    <div className="text">世界广播</div>
                </Link>


            </div>



            {isShowSelectHero ? <div className="heroSelectModal">
                <div className="bg" onClick={hideHeroSelect}></div>
                <div className="cardsContainer">

                    {assets && assets.heros.length ? <div className='cards_wrap'>
                        {assets.heros.map( (hero:any,index:number) => {
                            return <div  key={`hero_${index}`} 
                            onClick={ (e) => {setHeroTeam(e,hero.id)}}
                            ><HeroCard
                            hero={hero}
                            cl=''
                            ></HeroCard>
                            </div>
                        } )}
                    </div> 
                    : <div className="noHeros">
                        <p className='emoji'>😢</p>
                        <p>暂无可用的英雄</p>
                        {/* <div className="tip">
                            <p>了解更多，请咨询“像素屋”客服</p>
                        </div> */}
                    </div>}
                </div>
            </div> : null}

            
        


            
        </div>
    )
}

export default Homepage