import { useEffect, useState } from "react";
import './index.less';

interface Hero {
    category: any;
    hp: number;
    hp_default : number;
    attack: number;
    attack_default : number;
    defense: number;
    defense_default : number;
    id:number;
    image: any;
}

  interface HeroCardProps {
    hero: Hero;
    cl : string; 
    // onClick : (param: any) => void;
}

function HeroCard( { hero,cl} : HeroCardProps ) {
    const [image_url,setImage_url] =  useState<string>('');
    const [name,setName] =  useState<string>('');

    // const [cl,setCl] = useState<string>('');

    useEffect( () => {

        let image_url = '';
        if(hero.image){
            image_url = `https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_300,limit_0`;
        }else if(hero.category && hero.category.image){
            image_url = `https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_300,limit_0`;
        }else{
            image_url = '';
        }

        setImage_url(image_url)

        if(hero.category){
            setName(hero.category.cnName);
        }
     
    },[hero])


    return (
        <div className={cl || 'herocard hero'}>
        {image_url ? <img src={image_url} alt=''></img> : null}
        {name ? <div className="name" >
            {name}    
        </div> : null}
        <div className="datas">

            <div className="data_block">
                <div className="data_block_value">{hero.hp_default}</div>
                <div className="data_block_plus">{`+${hero.hp - hero.hp_default}`}</div>
                <div className="data_block_name">HP</div>
            </div>
            {/* attack */}
            <div className="data_block">
                <div className="data_block_value">{hero.attack_default}</div>
                <div className="data_block_plus">{`+${hero.attack - hero.attack_default}`}</div>
                <div className="data_block_name">攻击</div>
            </div>
            {/* defense */}
            <div className="data_block">
                <div className="data_block_value">{hero.defense_default}</div>
                <div className="data_block_plus">{`+${hero.defense - hero.defense_default}`}</div>
                <div className="data_block_name">防御</div>
            </div>
        </div>
    </div>
    );
  }
  
export default HeroCard
