// import React, { useContext } from 'react'
// import { useNavigate } from 'react-router-dom'
// import { AuthContext } from '../../context/AuthContext'
import './index.less';

const Login = () => {
  // const {authenticated, setAuthenticated} = useContext(AuthContext)
  const handleLogin = () => {

    if (/MicroMessenger/i.test(navigator.userAgent.toLowerCase())) {
        var currentUri = encodeURIComponent("https://toilet.usecubes.cn/");
        var redirect_uri = "https://toilet.usecubes.cn/wechatloginreturn?from=wx_inside&tokentype=requestlongify&redirect_uri=" + currentUri;
        redirect_uri = encodeURIComponent(redirect_uri);
        window.location.href = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx75e6a2be9fbd673d&redirect_uri=" + redirect_uri + "&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect";
    }else if(/aweme/.test(navigator.userAgent.toLowerCase())){
        var currentUri = encodeURIComponent("https://toilet.usecubes.cn/");
        // var redirect_uri = "https://toilet.usecubes.cn/dyloginreturn";
        // redirect_uri = encodeURIComponent(redirect_uri);
        window.location.href = "https://open.douyin.com/platform/oauth/connect?client_key=aw3ma69x6qpqomzr&response_type=code&scope=user_info&redirect_uri=https://toilet.usecubes.cn/dyloginreturn";
        // window.location.href = "https://open.douyin.com/platform/oauth/connect?client_key=aw3ma69x6qpqomzr&response_type=code&scope=user_info&redirect_uri="+redirect_uri+"&state=STATE";
    }else{
      alert('请使用微信登陆')
    }
  }

  return (
    <div id="loginPage" style={{
      backgroundImage : "url('/assets/images/login_bg.jpg')"
      }}>

        <div className="loginContent" style={{
         backgroundImage : "url('/assets/images/login_logo.png')"
          }}>
          <div className='login_button' onClick={() => handleLogin()}>
            <img src="/assets/images/login_normal.png" alt="" />
            <img src="/assets/images/login_werid.png" alt="" />
          </div>
        </div>
    </div>
  )
}

export default Login