import {  useState ,useEffect} from 'react';
import axios from 'axios';
import './index.less';
import { useParams,Link,useNavigate  } from 'react-router-dom';
import wx from 'weixin-js-sdk';

interface User {
    checkin : Date;
    city : string;
    computility : number;
    computilityLimit : number;
    country: string;
    created : Date;
    headimgurl : string;
    id : number;
    name : string;
    openid : string;
    platform : string;
    province : string;
    sex : number;
    silicon : number;
    unionid : string;
}



interface Core {
    category: any;
    hp_increment : number| string;
    attack_increment: number | string;
    defense_increment : number | string;
    id:number;
}

interface CoreCardProps {
    core: Core;
    cl : string; 
}
  

function CoreCard( { core,cl} : CoreCardProps ) {
    const [image_url,setImage_url] =  useState<string>('');
    const [name,setName] =  useState<string>('');

    useEffect( () => {
        setImage_url(`https://oss.usecubes.cn/${core.category.image.url}?x-oss-process=image/resize,w_300,limit_0`);
        setName(core.category.cnName);
    },[core])


    return (
        <div className={cl || 'card core'}>



        {image_url ? <img src={image_url} alt='' ></img> : null}
        {name ? <div className="name" >
            {name}    
        </div> : null}
        <div className="datas">
            {/* hp */}
            <div className="data_block">
                <div className="data_block_value">{core.category.hp_base}</div>
                <div className="data_block_plus">{`+${core.hp_increment}`}</div>
                <div className="data_block_name">HP</div>
            </div>
            {/* attack */}
            <div className="data_block">
                <div className="data_block_value">{core.category.attack_base}</div>
                <div className="data_block_plus">{`+${core.attack_increment}`}</div>
                <div className="data_block_name">攻击</div>
            </div>
            {/* defense */}
            <div className="data_block">
                <div className="data_block_value">{core.category.defense_base}</div>
                <div className="data_block_plus">{`+${core.defense_increment}`}</div>
                <div className="data_block_name">防御</div>
            </div>
        </div>
    </div>
    );
  }


  interface Equipment {
    category: any;
    hp_increment : number| string;
    attack_increment: number | string;
    defense_increment : number | string;
    id:number;
}


interface EquipmentCardProps {
    equipment: Equipment;
    cl : string; 
}
  

function EquipmentCard( { equipment,cl} : EquipmentCardProps ) {
    const [image_url,setImage_url] =  useState<string>('');
    const [name,setName] =  useState<string>('');

    useEffect( () => {
        setImage_url(`https://oss.usecubes.cn/${equipment.category.image.url}?x-oss-process=image/resize,w_300,limit_0`);
        setName(equipment.category.cnName);
    },[equipment])


    return (
        <div className={cl || 'card equipment'}>
        {image_url ? <img src={image_url} alt=''></img> : null}
        {name ? <div className="name" >
            {name}    
        </div> : null}
        <div className="datas">
            {/* hp */}
            <div className="data_block">
                <div className="data_block_value">{equipment.category.hp_increment_base}</div>
                <div className="data_block_plus">{`+${equipment.hp_increment}`}</div>
                <div className="data_block_name">HP</div>
            </div>
            {/* attack */}
            <div className="data_block">
                <div className="data_block_value">{equipment.category.attack_increment_base}</div>
                <div className="data_block_plus">{`+${equipment.attack_increment}`}</div>
                <div className="data_block_name">攻击</div>
            </div>
            {/* defense */}
            <div className="data_block">
                <div className="data_block_value">{equipment.category.defense_increment_base}</div>
                <div className="data_block_plus">{`+${equipment.defense_increment}`}</div>
                <div className="data_block_name">防御</div>
            </div>
        </div>
    </div>
    );
  }



  interface Hero {
    category: any;
    hp: number;
    hp_default : number;
    attack: number;
    attack_default : number;
    defense: number;
    defense_default : number;
    id:number;
}

  interface HeroCardProps {
    hero: Hero;
    cl : string; 
}


function HeroCard( { hero,cl} : HeroCardProps ) {
    const [image_url,setImage_url] =  useState<string>('');
    const [name,setName] =  useState<string>('');

    // const [cl,setCl] = useState<string>('');

    useEffect( () => {
        setImage_url(`https://oss.usecubes.cn/${hero.category.image.url}?x-oss-process=image/resize,w_300,limit_0`);
        setName(hero.category.cnName);
    },[hero])


    return (
        <div className={cl || 'card hero'}>
        {image_url ? <img src={image_url} alt=''></img> : null}
        {name ? <div className="name" >
            {name}    
        </div> : null}
        <div className="datas">

            <div className="data_block">
                <div className="data_block_value">{hero.hp_default}</div>
                <div className="data_block_plus">{`+${hero.hp - hero.hp_default}`}</div>
                <div className="data_block_name">HP</div>
            </div>
            {/* attack */}
            <div className="data_block">
                <div className="data_block_value">{hero.attack_default}</div>
                <div className="data_block_plus">{`+${hero.attack - hero.attack_default}`}</div>
                <div className="data_block_name">攻击</div>
            </div>
            {/* defense */}
            <div className="data_block">
                <div className="data_block_value">{hero.defense_default}</div>
                <div className="data_block_plus">{`+${hero.defense - hero.defense_default}`}</div>
                <div className="data_block_name">防御</div>
            </div>
        </div>
    </div>
    );
  }
  


function AsPage(props:any){

    const { hash } = useParams();
    const [address,setAddress] = useState<any>();
    const navigate = useNavigate();

        const [user,setUser] = useState<User>();



     // 获取用户信息
    useEffect( ()=> {
    axios.get(
        `/api/v1/skibidi_toilet_card/getUser`
    ).then( response => {
        if(response.statusText === 'OK'){
            setUser(response.data);
        }
    } )
    },[]);


    // 获取卡牌
    useEffect( ()=> {
        // console.log(props)
        // core hashs : [N0HLSyhr,PWbxntWD,com3dDaC,QekC9NQq,smd0FcLr,0DskgScv]
        // equipment hashs: [NIPxISmF,N2eHg3ea,cKqVO9xx,D9e7aPKf]
        // hero hashs : [BmI55B6P,Z3cKQrli,Em3e040q,s2dplXBv]
        axios.get(
        `/api/v1/skibidi_toilet_card/address?hash=${hash}`)
        .then( response => {
            if(response.statusText === 'OK'){
                setAddress(response.data);
            }
        } )

    },[]);

    const scanCode = () => {
        wx.scanQRCode({
        needResult: 0, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
        scanType: ["qrCode"], // 可以指定扫二维码还是一维码，默认二者都有
        success: function (res) {
          var result = res.resultStr; // 当needResult 为 1 时，扫码返回的结果
        }
      });
   }



    const add2Account = () =>{
        if(!address){return;}
        if(!address.hero && !address.equipment && !address.core){return;}

       return axios.get(
            `/api/v1/skibidi_toilet_card/address/addcard?hash=${hash}`)
            .then( response => {
                console.log(response)
                if(response.data && response.data.status === 400){
                    alert(response.data.message)
                }else if(response.statusText === "OK"  ){
                    navigate(`/cards/${user?.id}`, { replace: true }); // <-- redirect
                }
            } )
    }

    return (
        <div id="asPage">

            
            <div className="blackHoleBg">
                <div className="blackhole"></div>
            </div>


            <div className="cardContainer">
                {address && address.core ? 
                <div className='coreCardOuter'><CoreCard core={address.core}  cl={''} /></div>
                 : null}     

                  {address && address.equipment ? 
                <div className='coreCardOuter'><EquipmentCard equipment={address.equipment}  cl={''} /></div>
                 : null}   

                {address && address.hero ? 
                <div className='coreCardOuter'><HeroCard hero={address.hero}  cl={''} /></div>
                 : null}    
                 
                 <div className="btns">
                    <Link className='home_button' to={'/'}>&lt;我的主页</Link>

                    {address && address.user ? <div className="accept_tip">已被<b>{address.user.name}</b>添加</div>:
                    <div className="accept_button" onClick={add2Account}>收下</div>}
                 </div>
                 


                 
            </div>

            <header>
                <Link className="icon"  to={'/'}>
                    <img src="/assets/icons/back.svg" alt="" />
                </Link>
                添加卡牌
                <div className="scan" >
                    {/* <img src="/assets/icons/scan.svg" alt="" /> */}
                </div>
            </header>



        </div>
    )
}

export default AsPage