import { useRef, useState ,useEffect} from 'react';
import axios from 'axios';
import './index.less';

import { useParams,redirect,useNavigate  } from 'react-router-dom';


function WechatLoginReturn(props:any){

    const navigate = useNavigate();

    useEffect( ()=> {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const code = urlParams.get('code');
        if(code){
            axios.get(`/api/v1/skibidi_toilet_card/wechatlogin?code=${code}`)
            .then( response => {
                setTimeout( () => {
                    navigate(`/`, { replace: true }); // <-- redirect
                } ,1000)
            })
        }
    },[])

    return (
        <div id="wechatLoginReturnPage" style={{
            backgroundImage : "url('/assets/images/bg.jpg')"
        }}>

        </div>
    )
}

export default WechatLoginReturn