import React, { useContext } from 'react'
import {Routes as Router, Route, Navigate, Outlet} from 'react-router-dom'
import { AuthContext } from './context/AuthContext'

import EventPage from './pages/event';
import CardsPage from './pages/cards';
import ComposePage from './pages/compose';
import HongPage from './pages/hong';
import HomePage from './pages/home';
import UserPage from './pages/user';
import LoginPage from './pages/login';

import WechatLoginReturn from './pages/wechatloginreturn';
import DyLoginReturn from './pages/dyloginreturn';

import EventLandingPage from './pages/eventslanding'
import LastestHerosPage from './pages/lastestheros'

import AsPage from './pages/as';

type Props = {}



const PrivateRoutes = () => {
  const { authenticated } = useContext(AuthContext)
  if(!authenticated) return <Navigate to='/login' replace />
  return <Outlet />
}

const NotFound = () => {
  return (
    <>
      <h1>404 - Page Not Found</h1>
      <p>Sorry, the page you are looking for could not be found.</p>
    </>
  );
};

const Routes = (props: Props) => {
  const { authenticated } = useContext(AuthContext)

  return (
    <Router>
        <Route path='/login' element={<LoginPage />}/>
        <Route element={<PrivateRoutes />}>
          <Route path='/' element={<HomePage />} />
          <Route path='/event/:type' element={<EventLandingPage />} />
          <Route path='/event/:type/:eventid/:fightid/:scriptlogid' element={<EventPage />} />
          <Route path='/cards/:userId' element={<CardsPage />} />
          <Route path='/compose' element={<ComposePage />} />
          <Route path='/as/:hash' element={<AsPage />} />
          <Route path='/user/:userId' element={<UserPage />} />
          <Route path='/lastestheros' element={<LastestHerosPage />} />
        </Route>
        <Route path='/hong' element={<HongPage />} />
        <Route path='/wechatloginreturn' element={<WechatLoginReturn />} />
        <Route path='/dyloginreturn' element={<DyLoginReturn />} />

        <Route path="*" element={<NotFound />} />

    </Router>
  )
}

export default Routes