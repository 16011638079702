import { useRef, useState ,useEffect} from 'react';
import axios from 'axios';

import './index.less';

function HongPage()
{
    const [hero,setHero] = useState<any>();
    const [herotype,setHeroType] = useState<string>();


    useEffect( ()=>{
        //  获取用户的资产
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const id = urlParams.get('id');

       axios.get(
        `/api/v1/skibidi_toilet_card/hong?id=${id}`,{
            responseEncoding: 'utf8mb4',
            responseType: 'json',
            headers: {
                'Content-Type': 'application/json'
            }
        }
    ).then( response => {
        if(response.statusText === 'OK'){
            setHero(response.data);

            let hero_type = '';
            switch(response.data.type){
                case '力量':
                    hero_type = 'attack';
                break;

                case '坦克':
                    hero_type = 'defense';
                break;

                case '敏捷':
                    hero_type = 'speed';
                break;
            }
            setHeroType(hero_type);
        }
    } )
    },[])

    return (
        <div id="hongpage">
            <img className='bg' src='/assets/images/hong_bg@2x.jpg' alt="" />
            <div className="container">
                <div className="logo">
                    <img src="/assets/images/hong_logo@2x.png" alt="" />
                </div>

                {hero ? <>
                    <div className="hero_image">
                        <img 
                                className='hero_image_container'
                                src={`https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_300,limit_0`} 
                                style={{
                                    maskImage: `url(/assets/images/mask.svg)`,
                                    maskSize : '100%',
                                    maskRepeat:'no-repeat'
                                }}
                            alt="" />
                    </div>
                    <div className={`hero_name ${herotype}`}>
                        <div className="cn">{hero.cnName}</div>
                        <div className="en">{hero.name}</div>
                        <div className="lives">
                            星命&nbsp;&nbsp;
                            {Array(hero.lives).fill(null).map( (e,index) => {
                                return < img key={`star_${index}`} src={`/assets/icons/hong_star_defense.svg`}></img>
                            })}
                        </div>

                        <div className="tag"><span>{`${hero.type}型`}</span>
                        </div>
                    </div>
                    <div className={`hero_description`}>
                        <p>{hero.description}</p>
                    </div>
                </> : null}


                <div className="preview_button">
                    <img src="/assets/images/hong_button.png" alt="" />
                </div>



  


            </div>
        </div>
    )
}

export default HongPage