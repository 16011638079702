import { useRef, useState ,useEffect} from 'react';
import axios from 'axios';
import './index.less';

import { Link  } from 'react-router-dom';


function LastestHeros(props:any){
    // const navigate = useNavigate(); 
    const [heros,setHeros] = useState<any[]>();

    const [positions,setPositions] = useState(
        [{
            left : '10%',
            top : '10%',
            scale : 1
        },{
            left : '65%',
            top : '10%',
            scale : 0.6
        },{
            left : '5%',
            top : '35%',
            scale : 1.2
        },{
            left : '55%',
            top : '26%',
            scale : 0.8
        },{
            left : '60%',
            top : '45%',
            scale : 0.6
        },{
            left : '20%',
            top : '60%',
            scale : 0.7
        },{
            left : '5%',
            top : '80%',
            scale : 0.9
        },{
            left : '45%',
            top : '70%',
            scale : 1.2
        }]
    )

    // 获取最新的合成的英雄
    useEffect( ()=> {
        axios.get(`/api/v1/skibidi_toilet_card/lastestheros`)
        .then( response => {
            // console.log(response)
            if(response.statusText === "OK"){
                setHeros(response.data)
            }
        })

    },[])

    return (
        <div id="lastestHerosPage">

            <header>
                <Link className="icon"  to={'/'}>
                    <img src="/assets/icons/back.svg" alt="" />
                </Link>
                最新合成的芯片人
                <div className='icon'></div>
            </header>

            {heros?.map( (hero:any,index:number) => {

                const style = {
                    left : positions[index].left,
                    top : positions[index].top,
                    zoom : positions[index].scale
                }

                return <div key={`hero_${index}`} className='heroWrap' style={style}>
                    <div className="border"></div>
                    <div className="border2"></div>
                    <Link to={`/user/${hero.user.id}`}>
                        <img src={`https://oss.usecubes.cn/${hero.image.url}?x-oss-process=image/resize,w_200,limit_0`} alt="" />
                        <div className="name">{hero.user.name}</div>
                    </Link>
                </div>
            } )}



        </div>
    )
}

export default LastestHeros