import { useRef, useState ,useEffect, JSXElementConstructor, ReactElement, ReactFragment, ReactPortal} from 'react';
import { Link } from 'react-router-dom';

import axios from 'axios';

import './index.less';


interface User {
    checkin : Date;
    city : string;
    computility : number;
    computilityLimit : number;
    country: string;
    created : Date;
    headimgurl : string;
    id : number;
    name : string;
    openid : string;
    platform : string;
    province : string;
    sex : number;
    silicon : number;
    unionid : string;
}



interface Core {
    category: any;
    hp_increment : number| string;
    attack_increment: number | string;
    defense_increment : number | string;
    id:number;
}


interface CoreCardProps {
    core: Core;
    cl : string; 
}
  

function CoreCard( { core,cl} : CoreCardProps ) {
    const [image_url,setImage_url] =  useState<string>('');
    const [name,setName] =  useState<string>('');

    // const [cl,setCl] = useState<string>('');

    useEffect( () => {
        setImage_url(`https://oss.usecubes.cn/${core.category.image.url}?x-oss-process=image/resize,w_300,limit_0`);
        setName(core.category.cnName);
    },[core])


    return (
        <div className={cl || 'card core'}>
        {image_url ? <img src={image_url} alt='' ></img> : null}
        {name ? <div className="name" >
            {name}    
        </div> : null}
        <div className="datas">
            {/* hp */}
            <div className="data_block">
                <div className="data_block_value">{core.category.hp_base}</div>
                <div className="data_block_plus">{`+${core.hp_increment}`}</div>
                <div className="data_block_name">HP</div>
            </div>
            {/* attack */}
            <div className="data_block">
                <div className="data_block_value">{core.category.attack_base}</div>
                <div className="data_block_plus">{`+${core.attack_increment}`}</div>
                <div className="data_block_name">攻击</div>
            </div>
            {/* defense */}
            <div className="data_block">
                <div className="data_block_value">{core.category.defense_base}</div>
                <div className="data_block_plus">{`+${core.defense_increment}`}</div>
                <div className="data_block_name">防御</div>
            </div>
        </div>
    </div>
    );
  }

  interface Equipment {
    category: any;
    hp_increment : number| string;
    attack_increment: number | string;
    defense_increment : number | string;
    id:number;
}


interface EquipmentCardProps {
    equipment: Equipment;
    cl : string; 
}
  

function EquipmentCard( { equipment,cl} : EquipmentCardProps ) {
    const [image_url,setImage_url] =  useState<string>('');
    const [name,setName] =  useState<string>('');


    useEffect( () => {
        setImage_url(`https://oss.usecubes.cn/${equipment.category.image.url}?x-oss-process=image/resize,w_300,limit_0`);
        setName(equipment.category.cnName);
    },[equipment])


    return (
        <div className={cl || 'card equipment'}>
        {image_url ? <img src={image_url} alt=''></img> : null}
        {name ? <div className="name" >
            {name}    
        </div> : null}
        <div className="datas">
            {/* hp */}
            <div className="data_block">
                <div className="data_block_value">{equipment.category.hp_increment_base}</div>
                <div className="data_block_plus">{`+${equipment.hp_increment}`}</div>
                <div className="data_block_name">HP加成</div>
            </div>
            {/* attack */}
            <div className="data_block">
                <div className="data_block_value">{equipment.category.attack_increment_base}</div>
                <div className="data_block_plus">{`+${equipment.attack_increment}`}</div>
                <div className="data_block_name">HP加成</div>
            </div>
            {/* defense */}
            <div className="data_block">
                <div className="data_block_value">{equipment.category.defense_increment_base}</div>
                <div className="data_block_plus">{`+${equipment.defense_increment}`}</div>
                <div className="data_block_name">HP加成</div>
            </div>
        </div>

        {equipment.category.suitablecores && equipment.category.suitablecores.length ? <div className='suitablecores'>
            适配核心：{equipment.category.suitablecores.map( (core:any) => {
                return core.cnName
            })}
        </div> 

        :null}

            
    </div>
    );
  }




function ComposePage()
{

    const [cores,setCores] = useState<any[]>();
    const [equipments,setEquipments] = useState<any[]>();

    const [selectedCore,setSelectedCore] = useState<any>();
    const [selectedEquipments,setSelectedEquipments] = useState<Equipment[]>([]);
    
    const [corePosition,setCorePosition] = useState<any>();
    const [equipmentPositions,setEquipmentPositions] = useState<any[]>([]);

    // 是否开始合成
    const [startCompose,setStartCompose] = useState<boolean>(false);
    const [startComposeCore,setStartComposeCore] = useState<boolean>(false);
    const [startComposeEquipment,setStartComposeEquipment] = useState<boolean>(false);
    const [startComposeTurn,setStartComposeTurn] = useState<boolean>(false);

    const [composedHero,setComposedHero] = useState<any>();

    
    const coreContainerRef = useRef<HTMLDivElement|null>(null);
    const equipmentContainerRef = useRef<HTMLDivElement|null>(null);

    const [user,setUser] = useState<User>();



    // 获取用户信息
    useEffect( ()=> {
        axios.get(
            `/api/v1/skibidi_toilet_card/getUser`
        ).then( response => {
            if(response.statusText === 'OK'){
                setUser(response.data);
            }
        } )
    },[]);



    useEffect( ()=>{
        //  获取用户的资产

        if(user){

            axios.get(
                `/api/v1/skibidi_toilet_card/usercores?userId=${user.id}`,{
                    responseEncoding: 'utf8mb4',
                    responseType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                ).then( response => {
                    if(response.statusText === 'OK'){
                        setCores(response.data.cores);
                    }
            } )
    
            axios.get(
                `/api/v1/skibidi_toilet_card/userequipments?userId=${user.id}`,{
                    responseEncoding: 'utf8mb4',
                    responseType: 'json',
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
                ).then( response => {
                    if(response.statusText === 'OK'){
                        setEquipments(response.data.equipments);
                    }
            } )
        }


    },[user])

    // core 只有一个，所以删除就OK了。
    const rejectCore = (core:any,e:any) => {
        setSelectedCore(null);
        // 删除核心的同时，卸载装备
        setSelectedEquipments([])
    }


    const matchCoreAndEquipments = (core:any,equipments:any[]) => {
        
        if(!core){return true}
        if(!equipments || equipments.length == 0){return true}

        let isMatch:boolean = true;
        if(equipments && equipments.length){
            equipments.map(se => {
                if(se.category.suitablecores && se.category.suitablecores.length ){
                    if(se.category.suitablecores.find( (e:any) => e.cnName === core.category.cnName)){

                    }else{
                        isMatch = false;
                    }
                }
            })
        }
        return isMatch;
    }

    const selectCore = (core:any,e:any) => {
        setSelectedCore(null);
        // 更换核心的同时，卸载装备
        setSelectedEquipments([])

        setTimeout(()=>{
            if(matchCoreAndEquipments(core,selectedEquipments)){
                setSelectedCore(core);
                // console.log(coreContainerRef.current?.getBoundingClientRect());
                // 减去容器的坐标
                const deltaX = coreContainerRef.current?.getBoundingClientRect().left || 0;
                const deltaY = coreContainerRef.current?.getBoundingClientRect().top || 0;
                setCorePosition({x : e.target.getBoundingClientRect().left-deltaX,y:e.target.getBoundingClientRect().top - deltaY});
            }else{
                alert('装载失败，适配核心冲突')
            }
           
        },300)

        setTimeout(()=>{
            setCorePosition({x:3,y:10})
        },400)
    }


    const selectEquipment = (equipment: Equipment,e:any ) =>{

        if(!selectedCore){
            alert('请先装载核心');
            return;
        }

        if(selectedEquipments.length >= 2){
            alert('最多载入2个装备')
        }else{
            // 先搞清楚是第几个？
            setTimeout(()=>{
                if(matchCoreAndEquipments(selectedCore,[equipment])){
                    const newEquipmentPositions  = [...equipmentPositions];

                    // 减去容器的坐标
                    const deltaX = equipmentContainerRef.current?.getBoundingClientRect().left || 0;
                    const deltaY = equipmentContainerRef.current?.getBoundingClientRect().top || 0;
    
                    newEquipmentPositions.push({x : e.target.getBoundingClientRect().left-deltaX,y:e.target.getBoundingClientRect().top - deltaY});
                    setEquipmentPositions(newEquipmentPositions);
    
                    const newSelectedEquipments  = [...selectedEquipments];
                    newSelectedEquipments.push(equipment);
                    setSelectedEquipments(newSelectedEquipments);
                }else{
                    alert('装载失败，适配核心冲突')
                }
            },300)
    
            setTimeout(()=>{
                if(selectedEquipments.length === 0){
                    setEquipmentPositions([{x:3,y:10}])
                }else{
                    setEquipmentPositions([{x:3,y:10},{x:130,y:10}])
                }

            },600)

        }
    }


    const rejectEquipment = (equipment: Equipment,e:any ) =>{
        const  newSelectedEquipments = selectedEquipments.filter(function(el) { return el.id != equipment.id; }); 
        setSelectedEquipments(newSelectedEquipments);

        if(selectedEquipments.length === 2){
            setEquipmentPositions([{x:3,y:10}])
        }else  if(selectedEquipments.length === 1){
            setEquipmentPositions([])
        }
    }

    // 合成英雄。
    const composeHero = () => {

        if(selectedCore && selectedEquipments.length>=2){
            setStartCompose(true);
        }

        setTimeout( () => {
            setStartComposeCore(true);
        },500)

        setTimeout( () => {
            setStartComposeEquipment(true);
        },1000)

        // 开始旋转
        setTimeout( () => {
            setStartComposeTurn(true);
        },2000)

        const currentCoreID = selectedCore.id;
        const equipmentIds:number[] = [];

        selectedEquipments.map( (selectedEquipment) => {
            equipmentIds.push(selectedEquipment.id);  
        })


        axios.post(`/api/v1/skibidi_toilet_card/hero/compound`,{
            coreId : Number(currentCoreID),
            equipmentIds : equipmentIds,
            // userId : 1
        }).then( response => {
            if(response.statusText === 'Created'){

                if(response.data.message){
                    alert(response.data.message)
                }else{
                    setStartComposeTurn(false);
                    setComposedHero(response.data);
                }

            }
        } )
    }


    return (
        <div id="composePage">

        <div className="composeArea">
            <header>
                <Link className="icon"  to={'/'}>
                    <img src="/assets/icons/back.svg" alt="" />
                </Link>
                英雄合成
                <div 
                    className={`compose_button ${selectedCore && selectedEquipments.length>=2 ? 'ready' : ''}`}
                    onClick={composeHero}
                    >
                    <div className="cn">启动</div>
                    <div className="en" >start</div>
                </div>
            </header>

            <div className="compose_area_content">
                <div className="corearea">
                    <div className="placeholder">
                        <img className='sign' src="/assets/icons/core-icon.svg" alt="" />
                        <p>核心装载区</p>
                        <p className='tip'>点击下方卡片进行装载</p>
                        <p><img className='below' src="/assets/icons/below.svg" alt="" /></p>
                    </div>
                    <div className='coreContainer' ref={coreContainerRef}>
                        {selectedCore ? <div className='coreCardOuter'
                                         style={{left:`${corePosition.x}px`,top:`${corePosition.y}px`}}
                                         onClick={(e) => rejectCore(selectedCore, e)}
                                         ><CoreCard core={selectedCore}  cl={''} /></div> : null}
                    </div>
                </div>
                <div className="equipmentarea">

                    <div className="placeholder">
                        <img className='sign' src="/assets/icons/equipment-icon.svg" alt=""/>
                        <p>装备装载区</p>
                        <p className='tip'>点击下方卡片进行装载</p>
                        <p><img className='below' src="/assets/icons/below.svg" alt="" /></p>
                    </div>

                    <div className='equipmentsContainer' ref={equipmentContainerRef}>
                        {selectedEquipments.map( (selectedEquipment:Equipment,index:number) => {
                            return <div className='equipmentCardOuter' 
                                key={`selected_equipment_${index}`}
                                style={{left:`${equipmentPositions[index].x}px`,top:`${equipmentPositions[index].y}px`}}
                                onClick={(e) => rejectEquipment(selectedEquipment, e)}
                                ><EquipmentCard equipment={selectedEquipment}  cl={''} /></div> 
                        } )}
                    </div>

                </div>
            </div>
        </div>

        <div className="coreContent">
            <h3>核心选择：</h3>
            {cores && cores.filter( (e:any) => !e.hero && e.percentage === 1).length ? <div className='cardContent'>    
                {cores.filter( (e:any) => !e.hero && e.percentage === 1).sort( (a,b) => Number(a.category.no) - Number(b.category.no) ).map( (core:Core,index:number) => {
                    const isHidden = selectedCore?.id === core.id;
                    const cl = `card core ${isHidden ? 'hide' : ''}`
                    return <div className={isHidden ? 'disable' : ''} onClick={(e) => selectCore(core, e)} key={`core_${index}`} ><CoreCard core={core}  cl={cl} /></div> 
                })}
                </div> : <>
                    <p className='empty'>暂时没有可用的核心，快去收集卡牌吧。</p>
                </> }
        </div>
        
        <div className="equipmentContent">
            <h3>装备选择：</h3>
            { equipments && equipments.filter((e:any) => !e.hero && e.percentage === 1 ).length ? <div className='cardContent'>    
                {equipments.filter((e:any) => !e.hero && e.percentage === 1 ).sort( (a,b) => Number(a.category.no) - Number(b.category.no) ).map( (equipment:Equipment,index:number) => {
                    const isHidden = selectedEquipments?.find( selectedEquipment => selectedEquipment.id === equipment.id  )
                    const isEnable = matchCoreAndEquipments(selectedCore,[equipment]);


                    const cl = `card equipment ${isHidden ? 'hide' : ''}`
                    return <div  className={`cardWrap ${isEnable ? '' : 'disable'}`}  onClick={(e) => selectEquipment(equipment, e)} key={`equipment_${index}`} ><EquipmentCard equipment={equipment}  cl={cl} /></div> 
                })}
                </div> : <>
                <p  className='empty'>暂时没有可用的装备，快去收集卡牌吧。</p>
            </> }
        </div>


        {startCompose ? <div className='composeComponent'>
            <div className="composeComponentContainer">
                <div className={`composeComponentContainerPlace ${startComposeTurn? 'turn' : ''}`}>
                    {startComposeCore ? <>
                        <div className="composeComponent_core">
                            <img src={`https://oss.usecubes.cn/${selectedCore.category.image.url}?x-oss-process=image/resize,w_300,limit_0`} alt="" />
                        </div>
                    </> : null}

                    {startComposeEquipment ? <>
                        {selectedEquipments.map( (selectedEquipment:Equipment,index:number) => {
                            const style = {animationDelay : `${index *500}ms`}
                            return <div className='composeComponent_equipment' 
                                key={`composeComponent_equipment_${index}`} 
                                style={style}
                                >
                                    <img src={`https://oss.usecubes.cn/${selectedEquipment.category.image.url}?x-oss-process=image/resize,w_300,limit_0`} alt="" />
                                </div> 
                        } )}    
                    </> : null}

                    {composedHero ? <>
                        <div className="composeComponent_hero">
                            <img src={`https://oss.usecubes.cn/${composedHero.image.url}?x-oss-process=image/resize,w_300,limit_0`} alt="" />
                        </div>
                        <Link className="accept_button" to={`/cards/${user?.id}`} >查看</Link>              
                    </> : null }
                </div>
            </div>

            <Link to={`/`} className='seeitlater'>稍后在“卡册”中查看</Link>

            <div className="bg-animation">
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <div id='stars4'></div>
            </div>

        </div>:null}
        </div>
    )
}

export default ComposePage
